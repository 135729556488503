import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Loading } from '../loader/Loading';
import Alert from '../../components/Alert';
import Select from 'react-select';
import { fetchClients } from './workFlowAPI';

export function ClientsCol({selectedItem, setSelectedItem}) {
    const [name, setName] = useState('');
    const [data, setData] = useState(selectedItem?.client ? selectedItem.client: []);
    const [clients, setClients] = useState(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        getClients();
        return (() => {
            source.cancel('axios request canceled');
            setData([]);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedItem({...selectedItem, client: data});
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedClient) {
            setName(selectedClient.label);
        }
    }, [selectedClient]) // eslint-disable-line react-hooks/exhaustive-deps

    const createNewEntry = () => {
        if (name) {
            const foundName = data.find(e => (e.name.toLowerCase().trim() === name.toLowerCase().trim()));
            if (!foundName) {
                setData([{name: name, client_id: selectedClient?.value ? selectedClient.value : null}, ...data]);
                setSelectedClient(null);
            }
        }
        setName('');
    };

    const deleteItem = (idx) => {
        let d = data.filter((v, index) => idx !== index);
        setData(d);
    }

    const getClients = async () => {
        try {
            setLoading(true);
            const res = await fetchClients(source);
            if (res.length > 0) {
                setClients(res.map(r => ({label: r.name, value: r.id})));
            } else {
                setClients([]);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error?.message);
        }
    }

    return (<>
        {error ? <Alert error={error} ></Alert> : ''}
        { isLoading ? <Loading fullScreen="true" /> : <>
           
            <div className="table-modal-work-flow mt-5" style={{width: '50%'}}>
            <label>Suitable for clients</label>
            {clients ? <Select options={clients} onChange={(e) => setSelectedClient(e)} value={selectedClient} /> : ''}
                <div className='mt-1'>Client or niche name</div>
                <table className="table table-striped">
                    <tbody>
                        <tr key='add'>
                            <td><input className="form-control" id="name" onChange={(e) => setName(e.target.value)} value={name}></input></td>
                            <td className="text-center"><i className={`bi bi-plus-lg text-primary ps-1 pe-1 ${name || selectedClient ? '' : 'disabled'}`} onClick={() => createNewEntry()}/></td>
                        </tr>
                        {data?.map((data, index) =>
                            <tr key={index}>
                                <td>{data.name}</td>
                                <td className="text-center"><i className="bi bi-dash text-danger ps-1 pe-1" onClick={() => deleteItem(index)}/></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </> }
    </>);
}

import React, { useState } from "react";
import Modal from "./Modal";
import PreviewEmailContent from "./PreviewEmailContent";

export default function PreviewEmail({ emailBody, emailSubject, button = false, icon = false, id = 0, methodology, askForLink, signature}) {

  const [previewDisplay, setPreviewDisplay] = useState('smartphone');

  const previewModalProp = {
    title: 'Preview email',
    target: `preview_email_${id}`,
    disableFooter: true,
    size: previewDisplay === 'tablet' ? 'lg' : ''
  }
  return (
    <>
    {button ? <button className="btn btn-primary mb-2 me-2" data-bs-toggle="modal" data-bs-target={`#preview_email_${id}`}>{button}</button> : ''}
    {icon ? <div data-bs-toggle="modal" data-bs-target={`#preview_email_${id}`}>{icon}</div> : ''}
    <Modal props={previewModalProp}>
        <PreviewEmailContent
          icon={icon} 
          setPreviewDisplay={setPreviewDisplay}
          previewDisplay={previewDisplay}
          methodology={methodology}
          signature={signature}
          askForLink={askForLink}
          emailBody={emailBody}
          emailSubject={emailSubject?.length > 0 ? emailSubject[0] : ''} />
    </Modal>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { blockEmail, deleteBlockedEmail, fetchBlockedEmails } from './blockedEmailsAPI';
import axios from 'axios';
import { Loading } from '../loader/Loading';
import Alert from '../../components/Alert';
import ReactPaginate from 'react-paginate';
import Nav from '../../components/Nav';
import Modal from '../../components/Modal';
import { getTimeInterval } from '../../help/help';
import { Tooltip } from '../../components/Tooltip';
import { useLocation, useNavigate } from 'react-router-dom';

export function BlockedEmails() {
    const [pageOffset, setPageOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const per_page = 100;
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [validation, setValidation] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    let navigate = useNavigate();
    let location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search); 

    useEffect(() => {
        document.title = 'Blocked Emails - JournoResearch';
        return (() => {
            source.cancel('axios request canceled');
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadBlockedEmails();
    }, [urlSearchParams.get('page'), urlSearchParams.get('searchTerm')]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadBlockedEmails = async () => {
        let page = urlSearchParams.get('page');
        let searchTerm = urlSearchParams.get('searchTerm');
        setSearchTerm(searchTerm);
        await getBlockedEmails(page ? page - 1 : 0, searchTerm);
    }

    const getBlockedEmails = async (pageOffset = 0, searchTerm) => {
        setLoading(true);
        const page = pageOffset + 1;
        const response = await fetchBlockedEmails(per_page, page, searchTerm, source);
        if (response) {
            setPageOffset(parseInt(pageOffset));
            setDataTable(response.data);
            setPageCount(Math.ceil(response.total / per_page));
            setLoading(false);
        }
    }

    const handlePageChange = (event) => {
        urlSearchParams.delete('page');
        urlSearchParams.append('page', event.selected + 1);
        navigate(`./?${urlSearchParams}`);
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        setValidation(true);
    }

    const propModalBlockedEmail = {
        title: 'Add blocked email',
        target: 'add_blocked_email',
        canSave: email.length > 0,
        add: async () => {
            try {
                if (email.length > 0) {
                    setLoading(true);
                    const data = {email, message};
                    await blockEmail(data);
                    await getBlockedEmails();
                    setLoading(false)
                }
            }  catch (error) {
                setLoading(false)
                setError(error?.message);
            }
        }
    }

    const deleteBlockedEmailPropModal = {
        title: 'Delete blocked email',
        target: 'delete_blocked_email',
        nameSaveButton: 'DELETE',
        danger: true,
        add: async () => {
            try {
            setLoading(true)
            await deleteBlockedEmail(selectedEmail?.id);
            await getBlockedEmails();
            setLoading(false)
            }  catch (error) {
                setLoading(false)
                setError(error?.message);
            }
        }
    }

    const resetFields = () => {
        setEmail('');
        setMessage('');
        setValidation(false);
    }

    const formatDate = (date) => {
        const dateFormat = new Date(date + 'Z')
        return new Intl.DateTimeFormat('en-GB',{'dateStyle':'medium', 'timeStyle':'medium'}).format(dateFormat)
    }

    const resetPage = () => {
        urlSearchParams.delete('page');
        urlSearchParams.append('page', 1);
      }
    
    const search = () => {
        resetPage();
        urlSearchParams.delete('searchTerm');
        urlSearchParams.append('searchTerm', searchTerm);
        navigate(`./?${urlSearchParams}`);
    };

    return (<>
        {error ? <Alert error={error} ></Alert> : ''}
        {isLoading ? <Loading fullScreen="true" /> : <>
            <Modal props={deleteBlockedEmailPropModal}>
                Are you sure you want to delete <i>{selectedEmail?.email}</i>?
            </Modal>
            <Nav selectedListItem={{'name': 'Blocked Emails'}} spaceBetween={false}>
                <Modal props={propModalBlockedEmail}>
                    <label>Email</label>
                    <input type="text" className={`form-control ${validation && email === '' ? 'is-invalid' : ''}`} name="email" onChange={(e) => handleEmailChange(e.target.value)} value={email} />                        
                    <label className="mt-3">Message</label>
                    <input type="text" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)}/>
                </Modal>
                <div className='btn-group me-auto ms-3'>
                    <input type="text" className="form-control" placeholder="Search" onKeyPress={(e) => e.key === 'Enter' && search()} onChange={(e) => setSearchTerm(e.target.value)} value={!searchTerm ? '' : searchTerm}/>
                    <button className="btn btn-primary" type="button"  onClick={search}><i className="bi bi-search"></i></button>
                </div>
                <button className="btn me-2 btn-primary" data-bs-toggle="modal" data-bs-target="#add_blocked_email" onClick={resetFields}>Add blocked email</button>
            </Nav>
            <>
                {dataTable.length > 0 ?
                    <div className="row">
                        <div className="table-responsive">
                            <table className="table table-striped table-blocked-emails">
                                <thead>
                                    <tr>
                                        <th scope="col">Email</th>
                                        <th scope="col" className="col-table"><div>Message</div></th>
                                        <th scope="col" className="text-center">Bounce</th>
                                        <th scope="col" className="text-center">Complaint</th>
                                        <th scope="col" className="text-center">Unsubscribed</th>
                                        <th scope="col" className="text-center">Manual</th>
                                        <th scope="col" className="text-center">Added at</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dataTable.map((data, index) =>
                                        <tr key={index}>
                                            <td>{data.email}</td>
                                            <td className="col-table"><Tooltip text={data.message ? data.message : ''}><div>{data.message}</div></Tooltip></td>
                                            <td className="text-center">{data.bounce ? <i className="bi bi-check campaigns-icons text-success"></i> : ''}</td>
                                            <td className="text-center">{data.complaint ? <i className="bi bi-check campaigns-icons text-success"></i> : ''}</td>
                                            <td className="text-center">{data.unsubscribed ? <Tooltip text={data._unsubscribe ? data._unsubscribe : ''}><i className="bi bi-check campaigns-icons text-success"></i></Tooltip> : ''}</td>
                                            <td className="text-center">{data.manual ? <i className="bi bi-check campaigns-icons text-success"></i> : ''}</td>
                                            <td className="text-center pointer"><Tooltip text={formatDate(data.updated_at)}><div>{getTimeInterval(data.updated_at + 'Z')}</div></Tooltip></td>
                                            <td className="icons-add-delete">
                                                <i className="bi bi-person-x-fill text-danger ps-3"  data-bs-toggle="modal" data-bs-target="#delete_blocked_email" onClick={() => setSelectedEmail(data)}></i>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : ''}
            </>

            {!isLoading && pageCount > 0 ?
            <>
            <div className="d-none d-sm-flex nav-pagination">
                <ReactPaginate
                    previousLabel="&#x276E;"
                    nextLabel="&#x276F;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={pageOffset}
                />
            </div>
            <div className="d-flex d-sm-none nav-pagination">
                <ReactPaginate
                    previousLabel="&#x276E;"
                    nextLabel="&#x276F;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={pageOffset}
                />
            </div>
            </>
            : ''}
        </>}
    </>);
}

/* global Modernizr */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './main.scss';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
//import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/js/bootstrap.min.js";
import './modernizr-custom';

const html5Inputs = Modernizr.inputtypes['datetime-local'] && Modernizr.inputtypes.date;

ReactDOM.render(
  <React.StrictMode>
    {html5Inputs?
    <Provider store={store}>
      <BrowserRouter>
      <App />
      </BrowserRouter>
    </Provider>
    :<><h1>Please update your browser! We recommend the latest version of Chrome</h1></>}
  </React.StrictMode>,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
///serviceWorker.register();

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Loading } from '../loader/Loading';
import apiClient from '../../services/api';
import Alert from '../../components/Alert';
import Select from 'react-select';
import { UserSelect } from './UserSelect';
import { EditorTinyMCE } from './EditorTinyMCE';

export function PressReleaseCol({selectedItem, setSelectedItem, users}) {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [data, setData] = useState(selectedItem?.press_release ? selectedItem.press_release: []);
    const [pressReleaseOptions, setPressReleaseOptions] = useState(null);
    const [selectedPressRelease, setPressRelease] = useState(null);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        getPressReleases();
        return (() => {
            setPressReleaseOptions('');
            source.cancel('axios request canceled');
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSelectedItem({...selectedItem, press_release: data});
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedPressRelease) {
            setName(selectedPressRelease.name);
            setUrl(prUrl(selectedPressRelease.value));
        }
    }, [selectedPressRelease]) // eslint-disable-line react-hooks/exhaustive-deps

    const prUrl = (val) => {
        return `${process.env.REACT_APP_ENDPOINT}/press-releases/${val}`;
    }

    const createNewEntry = () => {
        if (name && url) {
            const foundUrl = data.find(e => (e.url.toLowerCase().trim() === url.toLowerCase().trim()));
            if (!foundUrl) {
                setData([{name: name, url: url, press_release_id: selectedPressRelease?.value ? selectedPressRelease.value : null}, ...data]);
                setPressRelease(null);
            }
        }
        setName('');
        setUrl('');
    };

    const deleteItem = (idx) => {
        let d = data.filter((v, index) => idx !== index);
        setData(d);
    }

    const getPressReleases = async () => {
        try {
            setLoading(true);
            const pressReleaseResponse = await apiClient.get('/api/press-releases-list', { cancelToken: source.token });
            const items = pressReleasesFormatAsOptions(pressReleaseResponse.data);
            setPressReleaseOptions(items);
            setLoading(false);
        } catch (error) {
            setLoading(false)
            setError(error?.message);
        }
    }

    const formatOptionPressReleaseLabel = ({label, path, name}) => (
        <div>
            <div className="path-select">{path}</div>
            <div>{name}</div>
        </div>
    );

    const pressReleasesFormatAsOptions = (items) => {
        return items.reduce( (acc, item) => acc =[...acc, { name: item.name, value: item.id, label: item.name + item.path, path: item.path}], [])
    };

    return (<>
        {error ? <Alert error={error} ></Alert> : ''}
        { isLoading ? <Loading fullScreen="true" /> : <>
            <EditorTinyMCE selectedItem={selectedItem} setSelectedItem={setSelectedItem} type={'press_release_description'} label={'Press Release description'}/>
            <div className="table-modal-work-flow mt-3">
            <label>Press Releases</label>
            {pressReleaseOptions ? <Select options={pressReleaseOptions} onChange={(e) => setPressRelease(e)} value={selectedPressRelease} formatOptionLabel={formatOptionPressReleaseLabel}/> : ''}
                <table className="table table-striped mt-2">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">URL</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key='add'>
                            <td><input className="form-control" id="pr-name" onChange={(e) => setName(e.target.value)} value={name}></input></td>
                            <td><input className="form-control" id="pr-url" onChange={(e) => setUrl(e.target.value)} value={url}></input></td>
                            <td><i className={`bi bi-plus-lg text-primary ps-1 pe-1 ${(name && url) || selectedPressRelease ? '' : 'disabled'}`} onClick={() => createNewEntry()}/></td>
                        </tr>
                        {data?.map((data, index) =>
                            <tr key={index}>
                                <td>{data.name}</td>
                                <td><a href={data.url} target="blank">{data.url}</a></td>
                                <td><i className="bi bi-dash text-danger ps-1 pe-1" onClick={() => deleteItem(index)}/></td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            <UserSelect selectedItem={selectedItem} setSelectedItem={setSelectedItem} itemKey='press_release_user' users={users}></UserSelect>   
        </> }
    </>);
}

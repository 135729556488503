export const selectFirstItem = (items) => {
    let foundItem = items.children.find(i => i.is_folder === 0);
    if (foundItem) {
        return foundItem;
    } else if(items.children.length > 0){
        items.children.forEach(i => {
            selectFirstItem(i);
        });
    }
};

export const diffBetweenDates = (date1, date2) => { 
    var d1 = new Date(date1);   
    var d2 = new Date(date2);
    var diff = d2.getTime() - d1.getTime();
    return diff / (1000 * 60 * 60 * 24);   
}

export const  getTimeInterval = (date) => {
    let seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let unit = "second";
    let direction = "ago";
    if (seconds < 0) {
      seconds = -seconds;
    }
    let value = seconds;
    if (seconds >= 31536000) {
      value = Math.floor(seconds / 31536000);
      unit = "year";
    } else if (seconds >= 86400) {
      value = Math.floor(seconds / 86400);
      unit = "day";
    } else if (seconds >= 3600) {
      value = Math.floor(seconds / 3600);
      unit = "hour";
    } else if (seconds >= 60) {
      value = Math.floor(seconds / 60);
      unit = "minute";
    }
    if (value !== 1)
      unit = unit + "s";
    return value + " " + unit + " " + direction;
  }

export const formatDateMedium = (date) => {
  const dateFormat = new Date(date)
  const  formatter = new Intl.DateTimeFormat('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hourCycle: 'h23'
  });
  const formatted = formatter.formatToParts(dateFormat);
  let stringDate = `${formatted[4].value} ${formatted[2].value} ${formatted[8].value}:${formatted[10].value}:${formatted[12].value}`;
  return stringDate;
}

export const formatDate = (date) => {
  const dateFormat = new Date(date + 'Z')
  return new Intl.DateTimeFormat('en-GB',{'dateStyle':'medium', 'timeStyle':'medium', hourCycle: 'h23'}).format(dateFormat)
}

export const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const checkActiveModule = (userData, name) => {
  return userData?.module?.find(m => m.name === name)?.status;
}

export const formatDateShort = (d, type = 'yyyy-mm-dd') => {
  let date = d ? new Date(d) : new Date();
  let day = ("0" + date.getDate()).slice(-2);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  if (type === 'yyyy-mm-dd') {
    return date.getFullYear()+"-"+(month)+"-"+(day) ;
  }
  return `${day}/${month}/${date.getFullYear()}`;
}

export const checkPermission = (role, permission, user) => {
  let userData = user ? user : JSON.parse(localStorage.getItem('loggedIn'))?.user;
  if (userData && userData[role]) {
    return userData[role]?.permissions?.includes(permission) || userData[role]?.permissions?.includes('*');
  }
  return false;
}

export const formatDateTime = (d, input = false) => {
  let date = d ? new Date(d) : new Date();
  let string;
  if (input) {
      date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      string = date.toISOString();
      string = string.substring(0, (string.indexOf("T")|0) + 6|0);

  } else {
      string = date.toISOString();
  }
  return string;
}
import React, { useEffect, useState } from 'react';
import { fetchWorkFlowItems } from './workFlowAPI';
import axios from 'axios';
import { Loading } from '../loader/Loading';
import Alert from '../../components/Alert';
import ReactPaginate from 'react-paginate';
import Nav from '../../components/Nav';
import { WorkFlowTable } from './WorkFlowTable';
import { useParams } from 'react-router';
import { useNavigate, useLocation } from 'react-router';

export function WorkFlow() {
    const [pageOffset, setPageOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [dataTable, setDataTable] = useState([]);
    const per_page = 50;
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const { id, type } = useParams();
    const [searchTerm, setSearchTerm] = useState('');
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        document.title = 'Work Flow Manager - JournoResearch';
        document.addEventListener('focusin', handlerFocusIn);
        return (() => {
            source.cancel('axios request canceled');
            document.removeEventListener('focusin', handlerFocusIn);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handlerFocusIn = (e) => {
        if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
            e.stopImmediatePropagation();
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(location.search);
        let page = params.get('page');
        let filter = params.getAll('filter');
        let searchTerm = params.get('searchTerm');
        setSearchTerm(searchTerm);
        getWorkFlowItems(page ? page - 1 : 0, filter, searchTerm);
        setPageOffset(0);
    }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

    const getWorkFlowItems = async (pageOffset = 0, filter = [], searchTerm = '') => {
        setLoading(true);
        try {
            const page = parseInt(pageOffset) + 1;
            const response = await fetchWorkFlowItems(per_page, page, filter, searchTerm, source);
            if (response) {
                setPageOffset(parseInt(pageOffset));
                setDataTable(response.data);
                setPageCount(Math.ceil(response.total / per_page));
                setLoading(false);
            }
        }
        catch( error) {
            setLoading(false)
            setError(error?.message);
        }
    }

    const handlePageChange = (event) => {
        setPageOffset(event.selected);
        let params =  new URLSearchParams(location.search);
        params.delete('page');
        params.append('page', event.selected + 1);
        navigate(`./?${params}`);
    };

    const deleteParamsEntry = (params, key, value) => {
        const newEntries = Array.from(params.entries()).filter(
          ([k, v]) => !(k === key && v === value)
        );
       return new URLSearchParams(newEntries);
    }

    const workFlowFilter = (name) => {
        let params =  new URLSearchParams(location.search);
        params.delete('page');
        params.append('page', 1);
        let filters = params.getAll('filter');
        if (!filters.includes(name)) {
            params.append('filter', name);
        } else {
            params = deleteParamsEntry(params, 'filter', name);
        }
        filters = params.getAll('filter');
        navigate(`./?${params}`);
    }

    const isActiveFilter = (name) => {
        return location.search.includes(name);
    }

    const search = async () => {
        let params =  new URLSearchParams(location.search);
        params.delete('page');
        params.delete('searchTerm');
        params.append('page', 1);
        params.append('searchTerm', searchTerm);
        navigate(`./?${params}`);
    };

    return (<>
        {error ? <Alert error={error} ></Alert> : ''}
        {isLoading ? <Loading fullScreen="true" /> : <>
            <Nav selectedListItem={{'name': 'Work Flow Manager'}} spaceBetween={false}>
                <div className='btn-group me-auto'>
                    <input type="text" className="form-control" placeholder="Search" onKeyPress={(e) => e.key === 'Enter' && search()} onChange={(e) => setSearchTerm(e.target.value)} value={!searchTerm ? '' : searchTerm}/>
                    <button className="btn btn-primary" type="button"  onClick={search}><i className="bi bi-search"></i></button>
                </div>
                <button className={`btn mb-1 mt-1 ms-2 ${isActiveFilter('assigned_to_me') ? ' btn-primary' : 'btn-outline-primary'}`} onClick={() => workFlowFilter('assigned_to_me')}>Assigned to me</button>
                <button className={`btn mb-1 mt-1 ms-2 ${isActiveFilter('completed') ? ' btn-primary' : 'btn-outline-primary'}`} onClick={() => workFlowFilter('completed')}>Idea approved</button>
            </Nav>
            {dataTable ? <WorkFlowTable dataTable={dataTable} id={id} type={type}/> : ''}
            {!isLoading && pageCount > 0 ?
            <>
            <div className="d-none d-sm-flex nav-pagination">
                <ReactPaginate
                    previousLabel="&#x276E;"
                    nextLabel="&#x276F;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={pageOffset}
                />
            </div>
            <div className="d-flex d-sm-none nav-pagination">
                <ReactPaginate
                    previousLabel="&#x276E;"
                    nextLabel="&#x276F;"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={pageCount}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={1}
                    onPageChange={handlePageChange}
                    containerClassName="pagination"
                    activeClassName="active"
                    hrefAllControl
                    forcePage={pageOffset}
                />
            </div>
            </>
            : ''}
        </>}
    </>);
}

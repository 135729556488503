import axios from 'axios';
import React, { useState, useEffect }  from 'react';
import { Loading } from '../loader/Loading';
import { createCommunication, fetchCommunication } from './clientInformationAPI';
export function ClientCommunicationTab({selectedTab, setError, tab, data}) {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [isLoading, setLoading] = useState(false);
    const [communications, setCommunications] = useState([]);
    const [comment, setComment] = useState('');

    useEffect(() => {
        return (() => {
            source.cancel('axios request canceled');
        });
    }, [selectedTab]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedTab === tab && data) {
            getCommunication(data);
        }
    }, [selectedTab, data]) // eslint-disable-line react-hooks/exhaustive-deps

    const addCommunication = async ( client, communication) => {
        try {
            setLoading(true);
            const data = {client: client, communication: communication};
            if (comment) {
                await createCommunication(data, source);
                await getCommunication(client);
            }
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
                setLoading(false);
            }
        }
    }

    const getCommunication = async ( client ) => {
        try {
            setLoading(true);
            const res = await fetchCommunication(client, source);
            setCommunications(res);
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
                setLoading(false);
            }
        }
    }

    return (<>
        {isLoading ? <Loading fullScreen="true" /> : <>
            {selectedTab === tab ? <>
                <div className="client-communication">
                    <div className="wrapper-communication">
                        {communications?.map((c,idx) => (
                            <div key={idx}>
                                <div className='item-communication' dangerouslySetInnerHTML={{ __html: c.communication +'<span>-'+c.name+'</span>' }} key={idx}></div>
                            </div>
                        ))}
                    </div>
                    <div className="divider mt-3 mb-3"></div>
                    <div className="form-floating mb-4">
                        <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea" style={{height: '100px'}}  onChange={(e) => setComment(e.target.value)}></textarea>
                        <label htmlFor="floatingTextarea">Comments</label>
                    </div>
                </div>
                <button className='btn btn-primary btn-save-communication' onClick={() => addCommunication(data, comment)}>Add</button>
            </> : ''}
        </>}
    </>
    );
}

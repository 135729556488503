import React, { useState } from "react";
import axios from "axios";
import { Loading } from "../loader/Loading";
import Alert from "../../components/Alert";
import "./assigned-campaigns.scss";
import { CampaignSelect } from "../campaigns-manager/CampaignSelect";
import {
  updateCampaign,
  fetchCampaign,
  fetchNotionCampaigns,
} from "../campaigns-manager/campaignsManagerAPI";
import Modal from "../../components/Modal";
import { AssignedCampaignsList } from "./AssignedCampaignsList";
import CampaignTimeline from "../campaigns-manager/CampaignTimeline";
import CampaignLinks from "../campaigns-manager/CampaignLinks";

export function AssignedCampaigns() {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [error, setError] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [isCampaignLoading, setCampaignLoading] = useState(false);
  const [notionCampaigns, setNotionCampaigns] = useState(null);
  const [remount, setRemount] = useState(0);
  const [isLoading, setLoading] = useState(false);

  const getCampaign = async (id) => {
    try {
      setCampaignLoading(true);
      const res = await fetchCampaign(id, source);
      const resNotionCampaigns = await fetchNotionCampaigns(res.site, source);
      let name = document.getElementById('edit_card');
      if (name) {
          name.querySelector('#exampleModalLabel').innerHTML = `Edit card ${res.site} (${res.invoice})`;
      }
      setNotionCampaigns(resNotionCampaigns);
      setCampaign(res);
      setCampaignLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        setError(error?.message);
      }
    }
  };

  const editCardModal = {
    title: "Edit card",
    target: "edit_card",
    size: 'xl',
    add: () => { updateCard() }
  };

  const updateCard = async (submitForApproval = false) => {
    try {
      setLoading(true);
      let data = {
        notion_campaigns: campaign.notion_campaigns,
        completed: campaign.completed ? 1 : 0,
        manual_override: campaign.manual_override ? 1 : 0,
        min_ht_links: campaign.min_ht_links,
        min_links: campaign.min_links,
        updated_at: campaign.updated_at,
        submit_for_approval: submitForApproval
      };
      if (campaign.manual_override) {
        data.ht_links = parseInt(campaign.ht_links);
        data.links = parseInt(campaign.links);
      }
      await updateCampaign(data, campaign.id, source);
      setRemount(remount + 1);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        setError(error?.message);
        setLoading(false);
      }
    }
  }

  const messageConfirm = 'Are you sure that the campaign has a min of 8 links, non syndicated and at least 2 HT links?'

  const onClickSubmitForApproval = async () => {
    if (window.confirm(messageConfirm)) {
      updateCard(true);
    }
  }

  const submitForApproval = () => {
    return !campaign?.submitted_date ? <button className="btn btn-warning me-auto" onClick={onClickSubmitForApproval} data-bs-toggle="modal"
    data-bs-target="#edit_card">Submit for review</button> : <div className="me-auto">Is marked for review</div>
  }

  return (
    <div className="user-assigned-campaigns">
      {error ? <Alert error={error}></Alert> : ""}
      <Modal props={editCardModal} footer={submitForApproval()} loading={isCampaignLoading}>
        {isCampaignLoading ? (
          <Loading fullScreen="true" />
        ) : (
          <div className="d-flex flex-wrap">
              <div style={{flex: '1 1 30%', padding: '5px'}} className='wrapper-campaign-select'>
                  <CampaignSelect campaign={campaign} notionCampaigns={notionCampaigns} setCampaign={setCampaign}/>
              </div>
              <div style={{flex: '1 1 70%', overflow: 'hidden', padding: '1.2rem', paddingBottom: '3rem'}} className="wrapper-campaign-links">
                  {campaign ? <CampaignTimeline campaign={campaign} setCampaign={setCampaign} /> : ''}
                  {campaign?.campaign_links ? <CampaignLinks links={campaign.campaign_links} site={campaign?.site}/> : 'Links not found'}
              </div>
          </div>
        )}
      </Modal>
      <div className="row">
        {isLoading ? <Loading fullScreen="true" /> : ""}
        <div className="d-flex flex-wrap p-1 wrapper-user-cards mb-4">
          <AssignedCampaignsList getCampaign={getCampaign} key={remount} />
        </div>
      </div>
    </div>
  );
}

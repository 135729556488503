import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Nav from '../../components/Nav';
import Alert from '../../components/Alert';
import { Loading } from '../loader/Loading';
import { CampaignsSectionManager } from './CampaignsSectionManager';
import { UsersSectionManager } from './UsersSectionManager';
import { updateCampaign, fetchCampaign, fetchNotionCampaigns } from './campaignsManagerAPI';
import Modal from '../../components/Modal';
import { CampaignSelect } from './CampaignSelect';
import { checkPermission } from '../../help/help';
import { useNavigate, useLocation } from 'react-router';
import CampaignTimeline from './CampaignTimeline';
import CampaignLinks from './CampaignLinks';

export function CampaignsManager() {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [card, setCard] = useState(null);
    const [remount, setRemount] = useState(0);
    const [campaign, setCampaign] = useState(null);
    const [notionCampaigns, setNotionCampaigns] = useState(null);
    const [isCampaignLoading, setCampaignLoading] = useState(false);
    const [selectedCardId, setSelectedCardId] = useState(null);
    const dragItem = useRef();
    const dragOverItem = useRef();
    let navigate = useNavigate();
    let location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search);
    const editCardRef = useRef();

    useEffect(() => {
        document.title = 'Campaigns Manager JournoResearch';
        const myModal = document.getElementById('edit_card');
        if (myModal) {
            myModal.addEventListener('hidden.bs.modal', onCloseModal);
        }
        return (() => {
            setCard(null);
            source.cancel('axios request canceled');
            if (myModal) {
                myModal.removeEventListener('hidden.bs.modal', onCloseModal);
            }
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let id = urlSearchParams.get('id');
        if (id) {
            getCampaign(id);

            editCardRef.current.click();
        }
    }, [urlSearchParams.get('id')]); // eslint-disable-line react-hooks/exhaustive-deps

    const dragStart = (e, position) => {
        dragOverItem.current = null;
        dragItem.current = position;
        let selectors = document.querySelectorAll('.d-flex.flex-wrap.wrapper-user-cards');
        selectors.forEach(selector => {
            selector.classList.add('pointer-events-none');
        });
        e.currentTarget.parentElement.classList.remove('pointer-events-none');
        // console.log(dragItem.current);
        // console.log(e.target.innerHTML);
    };

    const dragEnter = (e, position) => {
        e.preventDefault();
        e.currentTarget.children[1].classList.add('drag-over');
        dragOverItem.current = position;
        //console.log(e.target.innerHTML, 'enter', dragOverItem.current);
    };

    const dragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.children[1].classList.remove('drag-over');
        dragOverItem.current = null;
    };
    

    const drop = (e) => {
        e.preventDefault();
        let selectorsUserCards = document.querySelectorAll('.d-flex.flex-wrap.wrapper-user-cards');
        selectorsUserCards.forEach(selector => {
            selector.classList.remove('pointer-events-none');
            selector.classList.remove('drag-over');
        });
        if (dragOverItem.current && dragItem.current.user_id !== dragOverItem.current.user_id) {
            update(dragOverItem.current.user_id, dragItem.current.id, dragOverItem.current.user_name);
        }
    };

    const update = async (user_id, campaign_id) => {
        try {
            setLoading(true);
            let from = {...dragItem.current};
            const res = await updateCampaign({assigned_user: user_id, updated_at: from.updated_at}, campaign_id, source);
            let to = {...res, user_name: dragOverItem.current.user_name};
            setCard({from: from, to: to});
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
                setLoading(false);
            }
        }
    }

    const getCampaign = async (id) => {
        try {
            setCampaignLoading(true);
            setSelectedCardId(id); 
            const res = await fetchCampaign(id, source);
            const resNotionCampaigns = await fetchNotionCampaigns(res.site, source);
            // console.log(resNotionCampaigns);
            let name = document.getElementById('edit_card');
            if (name) {
                name.querySelector('#exampleModalLabel').innerHTML = `Edit card ${res.site} (${res.invoice})`;
            }
            urlSearchParams.delete('id');
            urlSearchParams.append('id', id);
            setNotionCampaigns(resNotionCampaigns);
            setCampaign(res);
            setCampaignLoading(false);
            navigate(`./?${urlSearchParams}`);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
            }
        }
    }

    const removeAssignedUser = async (campaign_id, updated_at) => {
        try {
            setLoading(true);
            await updateCampaign({assigned_user: '', user_name: '', updated_at: updated_at}, campaign_id, source);
            setCard(null);
            setRemount(remount + 1);
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
                setLoading(false);
            }
        }
    }

    const editCardModal = {
        title: 'Edit card',
        target: 'edit_card',
        size: 'xl',
        add: async () => {
            try {
                setLoading(true);
                let data = {
                    notion_campaigns: campaign.notion_campaigns,
                    completed: campaign.completed && checkPermission('role_pr', 'fe-access-campaign-manager') ? 1 : 0,
                    manual_override: campaign.manual_override ? 1 : 0,
                    min_ht_links: campaign.min_ht_links,
                    min_links: campaign.min_links,
                    updated_at: campaign.updated_at,
                    completed_date: campaign.completed ? new Date(campaign.completed_date).toUTCString() : null
                };
                if (campaign.manual_override) {
                    data.ht_links = parseInt(campaign.ht_links);
                    data.links = parseInt(campaign.links);
                }
                await updateCampaign(data, campaign.id, source);
                setRemount(remount + 1);
                setLoading(false);
            } catch (error) {
                if (error?.message !== 'axios request canceled') {
                    setError(error?.message);
                    setLoading(false);
                }
            }
        }
    }

    const onCloseModal = () => {
        let searchParams = new URLSearchParams(document.location.search)
        searchParams.delete('id')
        navigate(`./?${searchParams}`)
    }

    const onClickRemoveMarkedForReview = async () => {
        let data = {submit_for_approval: null, updated_at: campaign.updated_at,};
        try {
            await updateCampaign(data, campaign.id, source);
            setRemount(remount + 1);
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
                setLoading(false);
            }
        }
    }

    const removeMarkedForReview = () => {
        return campaign?.submitted_date ? <button className="btn btn-warning me-auto" onClick={onClickRemoveMarkedForReview} data-bs-toggle="modal"
        data-bs-target="#edit_card">Remove marked for review</button> : ''
      }

    return (<>
        {error ? <Alert error={error} ></Alert> : ''}
        <button type="button" style={{display: 'none'}} data-bs-toggle="modal" data-bs-target="#edit_card" ref={editCardRef}></button>
        <Modal props={editCardModal} footer={removeMarkedForReview()} loading={isCampaignLoading}>
        {isCampaignLoading ? <Loading fullScreen="true" /> :
            <div className="d-flex flex-wrap">
                <div style={{flex: '1 1 30%', padding: '5px'}} className='wrapper-campaign-select'>
                    <CampaignSelect campaign={campaign} notionCampaigns={notionCampaigns} setCampaign={setCampaign}/>
                </div>
                <div style={{flex: '1 1 70%', overflow: 'hidden', padding: '1.2rem', paddingBottom: '3rem'}} className='wrapper-campaign-links'>
                    {campaign ? <CampaignTimeline campaign={campaign} setCampaign={setCampaign} /> : ''}
                    {campaign?.campaign_links ? <CampaignLinks links={campaign.campaign_links} site={campaign?.site}/> : 'Links not found'}
                </div>
            </div> }
        </Modal>
        <Nav selectedListItem={{'name': 'Campaigns Manager'}}/>
        <div className='row' onClick={() => setSelectedCardId(0)}>
            {isLoading ? <Loading fullScreen="true" /> : ''}
            <div className="mb-2 col-xxl-6 col-xl-6 col-xs-12">
                <CampaignsSectionManager dragStart={dragStart} drop={drop} card={card} key={remount} remount={remount} setRemount={setRemount} selectedCardId={selectedCardId} />
            </div>
            <div className="mb-2 col-xxl-6 col-xl-6 col-xs-12">
                <UsersSectionManager dragEnter={dragEnter} dragStart={dragStart} dragLeave={dragLeave} drop={drop} card={card} removeAssignedUser={removeAssignedUser} key={remount} selectedCardId={selectedCardId}/>
            </div>
        </div>
    </>
    );
}

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MailsInQueue } from '../../components/MailsInQueue';
import Nav from '../../components/Nav';
import { checkActiveModule, checkPermission } from '../../help/help';
import { resetItems } from '../lists/listNavigationSlice';
import { Events } from './Events';
import { LatestActivity } from './LatestActivity';
import { TrainingItems } from './TrainingItems';
import { SuccessfulCampaigns } from './SuccessfulCampaigns';
import { AssignedCampaigns } from './AssignedCampaigns';

export function Home() {
  const user = JSON.parse(localStorage.getItem('loggedIn'))?.user;
  const dispatch = useDispatch();
  const [viewMode, setViewMode] = useState('Dashboard');
  const [showLastMonth, setShowLastMonth] = useState(true);
  const [hideSwitch, setHideSwitch] = useState({ownCampaigns: false, allCampaigns: false});

  useEffect(() => {
    document.title = 'Home - JournoResearch';
    const mode = sessionStorage.getItem('viewModeHome');
    if (mode) {
      setViewMode(mode);
    }
    dispatch(resetItems);
  }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const setView = (viewMode) => {
    sessionStorage.setItem('viewModeHome', viewMode);
    setViewMode(viewMode)
  }

  return (<>
    <Nav selectedListItem={{'name':viewMode}} spaceBetween={false}>
          <MailsInQueue />
          <li className="nav-item pe-2 ms-auto"></li>
          {(!hideSwitch.ownCampaigns && viewMode !== 'Successful Campaigns') || (!hideSwitch.allCampaigns && viewMode === 'Successful Campaigns') ?
          <div className="form-check form-switch me-2 mt-1 mb-1">
            <input className="form-check-input" type="checkbox" role="switch" id="last_month" checked={!showLastMonth} onChange={() => setShowLastMonth(!showLastMonth)}/>
            <label className="form-check-label" htmlFor="last_month" readOnly>Show all</label>
          </div> : <></>}
          <button className={`btn me-2 mt-1 mb-1 ${viewMode === 'Dashboard' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setView('Dashboard')}>Dashboard</button>
          <button className={`btn me-2 mt-1 mb-1 ${viewMode === 'Table' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setView('Table')}>Table</button>
          {checkPermission('role_pr', 'fe-successful-campaigns') ? 
            <button className={`btn me-2 mt-1 mb-1 ${viewMode === 'Successful Campaigns' ? 'btn-primary' : 'btn-secondary'}`} onClick={() => setView('Successful Campaigns')}>Successful Campaigns</button> 
          : <></>}
    </Nav>
    {<SuccessfulCampaigns showOwnCampaigns={true} showLastMonth={showLastMonth} setHideSwitch={setHideSwitch} hideSwitch={hideSwitch}/>}
    <AssignedCampaigns/>
    {checkActiveModule(user, 'training-items') ? <TrainingItems /> : ''}
    {viewMode === 'Dashboard' ? <Events /> : <></>}
    {viewMode === 'Table' ? <LatestActivity /> : <></>}
    {viewMode === 'Successful Campaigns' && checkPermission('role_pr', 'fe-successful-campaigns') ? <SuccessfulCampaigns showLastMonth={showLastMonth} setHideSwitch={setHideSwitch} hideSwitch={hideSwitch}/> : <></>}
  </>
     
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
import apiClient from "../../services/api";
import { Loading } from "../loader/Loading";
import Alert from "../../components/Alert";
import { Tooltip } from "../../components/Tooltip";
import "./successful-campaigns.scss";
import { checkPermission } from "../../help/help";
import Wrapper from '../../components/Wrapper';

export function SuccessfulCampaigns({showOwnCampaigns = false, showLastMonth, setHideSwitch, hideSwitch}) {
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const extraCampaigns = 4;

  const range = (count) => [...Array(count).keys()];

  const getMonthHeaderClass = (index, minCampaigns, max) => {
    let className = [];
    className.push("header");

    if (index === 1) {
      className.push("first");
    } else if (index === minCampaigns) {
      className.push("min");
    }
    if (index === max) {
      className.push("last");
    }

    return className.join(" ");
  };

  const getMonthCellClass = (index, countCampaigns, minCampaigns, max) => {
    let className = [];
    className.push("cell");

    if (index > minCampaigns) {
      className.push(index <= countCampaigns ? "green" : "silver");
    } else {
      className.push(index > countCampaigns ? "red" : "green");
    }

    if (index === max) {
      className.push("last");
    }

    return className.join(" ");
  };

  const fetchUserSuccessfulCampaigns = async function (source) {
    try {
      const response = await apiClient.get(`/api/campaigns-manager/user-successful-campaigns`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  };

  const fetchUsersSuccessfulCampaigns = async function (source) {
    try {
      const response = await apiClient.get(`/api/dashboard/users-successful-campaigns`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  };

  const getUserSuccessfulCampaigns = async () => {
    try {
      setLoading(true);
      let obj = [];
      if (showOwnCampaigns) {
        obj = await fetchUserSuccessfulCampaigns(source);
        if (!obj?.minCampaigns) {
          setHideSwitch({...hideSwitch, ownCampaigns: true});
        }
      } else if (checkPermission('role_pr', 'fe-successful-campaigns') && !showOwnCampaigns) {
        obj = await fetchUsersSuccessfulCampaigns(source);
        if (obj.length === 0) {
          setHideSwitch({...hideSwitch, allCampaigns: true});
        } else {
          setHideSwitch({...hideSwitch, allCampaigns: false});
        }
      }
      
      setData(obj);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        setError(error?.message);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getUserSuccessfulCampaigns();
    return () => {
      source.cancel("axios request canceled");
      setData(null);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const showData = (data) => {
    return <>
      <h6>
        {data?.role} ({data?.minCampaigns})
      </h6>
      <div className="months">
        {data?.months.map((month, idx) => 
          {  return !showLastMonth || idx === data.months.length - 1 ? <div key={month.header} className="month">
            <h6>
              {month.header} - {month.count}
            </h6>
            <div className="campaigns">
              {range(
                Math.max(data.minCampaigns + extraCampaigns, month.count)
              ).map((index) => (
                <div key={index} className="month-cell">
                  <div
                    className={getMonthHeaderClass(
                      index + 1,
                      data.minCampaigns,
                      Math.max(data.minCampaigns + extraCampaigns, month.count)
                    )}
                  ></div>
                  {index < month.campaigns.length ? (
                    <Tooltip text={month.campaigns[index]}>
                      <div
                        className={getMonthCellClass(
                          index + 1,
                          month.count,
                          data.minCampaigns,
                          Math.max(
                            data.minCampaigns + extraCampaigns,
                            month.count
                          )
                        )}
                      ></div>
                    </Tooltip>
                  ) : (
                    <div
                      className={getMonthCellClass(
                        index + 1,
                        month.count,
                        data.minCampaigns,
                        Math.max(
                          data.minCampaigns + extraCampaigns,
                          month.count
                        )
                      )}
                    ></div>
                  )}
                </div>
              ))}
            </div>
          </div> : <Wrapper key={month.header}></Wrapper>}
        )}
      </div>
    </>
  }

  if (isLoading) {
    return (
      <div className="user-successful-campaigns">
        <Loading fullScreen="true" />
      </div>
    );
  }

  if (showOwnCampaigns && !data?.minCampaigns) {
    return <></>;
  }

  return (
    <div className="user-successful-campaigns">
      {error ? <Alert error={error}></Alert> : ""}
      {showOwnCampaigns ? showData(data) : <></>}
      {checkPermission('role_pr', 'fe-successful-campaigns') && !showOwnCampaigns && data?.map((item, idx) => <div style={{marginBottom: '2.5rem'}} key={idx}>
        <h5><b>{item.name}</b></h5>
        {showData(item.data)}
      </div>)
      }
    </div>
  );
}

import React from 'react';
import { Outlet } from 'react-router-dom';
import NotificationModal from '../features/notifications/NotificationModal';
import Navigation from './Navigation';

export default function Layout() {
    return (<>
        <NotificationModal />
        <Navigation />
        <div className="wrapper-container">
            <Outlet />
        </div>
    </>);
}
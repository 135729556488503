import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemById, loading, menuItems, resetItems } from '../lists/listNavigationSlice';
import { useParams } from 'react-router';
import { Loading } from '../loader/Loading';
import Nav from '../../components/Nav';
import { updatePressRelease, previewPressRelease, fetchTemplates, pressReleaseRecords, getLatestRecord, getRecordById, fetchPressReleasesById } from './pressReleasesSlice';
import { Editor } from '@tinymce/tinymce-react';
import Alert from '../../components/Alert';
import axios from 'axios';
import ReactHtmlParser from 'html-react-parser';
import './pressReleases.scss';
import Modal from '../../components/Modal';
import AutoSave from '../../components/AutoSave';
import { storeFiles, getAllImages } from './pressReleasesAPI';

export function PressReleases() {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [selectedPressRelease, setSelectedPressRelease] = useState(null);
    const { id } = useParams();
    const [emailSubjects, setEmailSubjects] = useState(['']);
    const [emailBody, setEmailBody] = useState(null);
    const [error, setError] = useState('');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [relentlessMode, setRelentlessMode] = useState(false);
    const [previewDisplay, setPreviewDisplay] = useState('smartphone');
    const [wysiwyg, setWysiwyg] = useState(null);
    const [askForLinkWysiwyg, setAskForLinkWysiwyg] = useState(null);
    const [methodologyWysiwyg, setMethodologyWysiwyg] = useState(null);
    const [signatureWysiwyg, setSignatureWysiwyg] = useState(null);
    const user = JSON.parse(localStorage.getItem('loggedIn'))?.user;
    const [askForLink, setAskForLink] = useState('');
    const [methodology, setMethodology] = useState('');
    const [signature, setSignature] = useState('');
    const [templates, setTemplates] = useState(null);
    const [selectedTemplate, setSelectedTemplate] = useState({title: '', content: ''});
    const [pressReleaseLoaded, setPressReleaseLoaded] = useState(false);
    const [latestRecord, setLatestRecord] = useState(null);
    const [records, setRecords] = useState(null);
    const [isRestored, setIsRestored] = useState(false);
    const [warningEmailBodySize, setWarningEmailBodySize] = useState(false);
    const localMenuItems = useSelector(menuItems);
    const localLoadingMenuItems = useSelector(loading);    

    const tinymcepath = user?.use_local_tinymce? process.env.REACT_APP_ENDPOINT + '/tinymce/tinymce.min.js': null;

    const getSelectedPressReleases = async (id, menuItems) => {
        try {
            let pressReleaseSelected = menuItems?.children.find(c => c.id === parseInt(id));
            let responseItemById = null;
            if (!pressReleaseSelected || pressReleaseSelected.is_folder) {
                responseItemById = await dispatch(fetchItemById({ id, typeOfList: 'press-releases', source })).unwrap();
                pressReleaseSelected = responseItemById.children.find(c => c.id === parseInt(id)); 
            }
            if (pressReleaseSelected?.is_folder === 0) {
                setLoading(true);
                if (id && !templates) {
                    const templates = await dispatch(fetchTemplates({ source })).unwrap();
                    setTemplates(templates);
                }
                const record = await getLatestRecord(id);
                if (Object.keys(record).length > 0) {
                    setLatestRecord(record);
                }
                const press_releases = await dispatch(fetchPressReleasesById({id , source})).unwrap();
                if (press_releases) {
                    setEmailBody(press_releases.email_body);
                    setWarningEmailBodySize(checkSizeStringInMb(press_releases.email_body));
                    setWysiwyg(press_releases.email_body);
                    setAskForLinkWysiwyg(press_releases.ask_for_link);
                    setMethodologyWysiwyg(press_releases.methodology);
                    setSignatureWysiwyg(press_releases.signature);
                    setRelentlessMode(press_releases.relentless_mode);
                    setAskForLink(press_releases.ask_for_link);
                    setMethodology(press_releases.methodology);
                    setSignature(press_releases.signature);
                    updateEmailSubjects(press_releases.email_subjects, press_releases.relentless_mode);
                    setSelectedPressRelease(press_releases);
                    setPressReleaseLoaded(true);
                }
                setLoading(false);
            } else {
                setSelectedPressRelease(null);
                setLoading(false);
            }
            
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setLoading(false);
                setError(error?.message);
            }

        }
    }

    useEffect(() => {
        document.title = 'Press Releases - JournoResearch';
        return (() => {
            dispatch(resetItems());
            setSelectedPressRelease(null);
        });
    }, [dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (id) {
            getSelectedPressReleases(id, localMenuItems);
        } else {
            getSelectedPressReleases(0, localMenuItems);
        }
        return (() => {
            source.cancel('axios request canceled');
            setRelentlessMode(null);
            setPreviewDisplay('smartphone');
            setPressReleaseLoaded(false);
            setLatestRecord(null);
            setIsRestored(false);
        });
    }, [dispatch, id]) // eslint-disable-line react-hooks/exhaustive-deps

    const checkSizeStringInMb = (string) => {
        let size = new Blob([string]).size;
        if (size / (1024 * 1024) >= 1) {
            return true;
        }
        return false
    }

    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            let content = editorRef.current.getContent();
            setWarningEmailBodySize(checkSizeStringInMb(content)); 
            setEmailBody(content);
        }
    };

    const signatureRef = useRef(null);
    const logSignature = () => {
        if (signatureRef.current) {
            setSignature(signatureRef.current.getContent());
        }
    };

    const methodologyRef = useRef(null);
    const logMethodology = () => {
        if (methodologyRef.current) {
            setMethodology(methodologyRef.current.getContent());
        }
    };

    const askForLinkRef = useRef(null);
    const logAskForLink = () => {
        if (askForLinkRef.current) {
            setAskForLink(askForLinkRef.current.getContent());
        }
    };

    const emailTemplate = () => {
        const existEmptyEmailSubject = emailSubjects?.includes('');
        if (existEmptyEmailSubject || !emailBody || !selectedPressRelease) return;
        return {
            'email_body': emailBody,
            'name': selectedPressRelease.name,
            'parent_id': selectedPressRelease.parent_id,
            'user_id': user.id,
            'is_folder': 0,
            'relentless_mode': relentlessMode,
            'email_subjects': emailSubjects,
            'ask_for_link': askForLink,
            'methodology': methodology,
            'signature': signature
        };
    };

    const savePressRelease = async () => {
        const template = emailTemplate();
        if (template) {
            setLoading(true);
            try {
                await dispatch(updatePressRelease({ id: selectedPressRelease.id, data: template })).unwrap();
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error?.message);
            }
        }
    };

    const updateEmailSubjects = (value, relentlessMode, index = 0) => {
        const nrOfInputs = Array.isArray(value) && value?.length > 5 ? 10 : 5;
        let currentValues = [...emailSubjects];
        if ( Array.isArray(value) && value.length > 0) {
            currentValues = [...value, ...Array(nrOfInputs - value.length).fill('')]
        }  
        if (value !== null && value !== undefined && !Array.isArray(value)) {
            currentValues[index] = value;
        }
        if (!relentlessMode) {
            currentValues = Array.isArray(value) ? [value[0]] : [value];
        }
        setEmailSubjects(currentValues);
    }

    const onChangeRelentlessMode = (relentlessMode) => {
        if (relentlessMode) {
            setEmailSubjects([...emailSubjects, ...Array(5 - emailSubjects.length).fill('')])
        } else {
            setEmailSubjects([emailSubjects[0]])
        }
        setRelentlessMode(relentlessMode);
    }

    const sendPreviewEmail = async () => {
        try {
            await savePressRelease();
            setLoading(true);
            await dispatch(previewPressRelease({ id: selectedPressRelease.id, data: [user.email] })).unwrap();
            setLoading(false);
        }  catch (error) {
            setLoading(false);
            setError(error?.message);
        }
    };

    const modalPropPreviewEmail = {
        title: 'Send preview email',
        target: 'preview_email',
        nameSaveButton: 'Send',
        add: sendPreviewEmail
    }

    const modalPropTemplates = {
        title: selectedTemplate['title'] ? selectedTemplate['title'] : '',
        target: 'template',
        disableFooter: true,
        size: 'lg'
    }

    const setAllInputValues = (value) => {
        const values = emailSubjects.map(() => (value));
        setEmailSubjects(values);
    }

    const dialogConfig = (callback, images) => ({
        title: 'Image Manager',
        body: {
            type: 'panel', // The root body type - a Panel or TabPanel
            items: images ? images.map((i, idx) => (
                {
                    type: 'htmlpanel', 
                    html: `<div class="gallery-div">
                            <label class="label-image">
                                <input style="display: none;" type="radio" name="image"  value="${i.path}" data-name="${i.name}"/>
                                <span><img class="image-media" src="${i.path}" width="40" height="40"/>${i.name}</span>
                            </label>
                        </div>`
                })) : []
        },
        buttons: [
          {
            type: 'cancel',
            name: 'closeButton',
            text: 'Cancel'
          },
          {
            type: 'submit',
            name: 'submitButton',
            text: 'Import',
            primary: true
          }
        ],
        onSubmit: function (api) {
          let selectedImage = document.querySelectorAll("input[name=image]:checked")[0]?.value;
          let selectedImageTitle = document.querySelectorAll("input[name=image]:checked")[0]?.getAttribute('data-name');
          if (selectedImage && selectedImageTitle) {
            callback(selectedImage, {title: selectedImageTitle});
          }
          api.close();
        }
    });

    const initEditor = {
        elementpath: false,
        branding: false,
        height: 700,
        plugins: 'print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist tinymcespellchecker a11ychecker imagetools textpattern noneditable help formatpainter permanentpen pageembed charmap tinycomments mentions  linkchecker emoticons advtable export',
        mobile: {
            plugins: 'print preview powerpaste casechange importcss searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media mediaembed template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists checklist tinymcespellchecker a11ychecker textpattern noneditable help formatpainter pageembed charmap mentions linkchecker emoticons advtable'
        },
        menu: {
            tc: {
            title: 'Comments',
            items: 'addcomment showcomments deleteallconversations'
            }
        },
        menubar: 'file edit view insert format tools table tc help',
        toolbar: 'undo redo | bold italic underline strikethrough | mybutton | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | image pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment',
        image_advtab: true,
        importcss_append: true,
        // templates: [
        //         { title: 'New Table', description: 'creates a new table', content: '<div className="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        //     { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
        //     { title: 'New list with dates', description: 'New List with dates', content: '<div className="mceTmpl"><span className="cdate">cdate</span><br /><span className="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
        // ],
        template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
        template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
        image_caption: true,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'sliding',
        spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
        tinycomments_mode: 'embedded',
        contextmenu: 'link image imagetools table configurepermanentpen',
        a11y_advanced_options: true,
        mentions_selector: '.mymention',
        mentions_item_type: 'profile',
        powerpaste_word_import: 'prompt',
        powerpaste_html_import: 'prompt',
        images_upload_handler: async function (blobInfo, success, failure) {
            try {
                const formData = new FormData();
                formData.append('file[]', blobInfo.blob(), blobInfo.filename());
                const res = await storeFiles(formData, id);
                success(res['path']);
                  
            } catch (e) {
                failure(e?.message);
            }
        },
        image_title: true,
        file_picker_types: 'image',
        //default_link_target: '_blank',
        extended_valid_elements : "a[href|target=_blank|rel:follow]",
        setup: function (editor) {
            editor.ui.registry.addMenuButton('mybutton', {
                text: 'Placeholder text',
                fetch: function (callback) {
                    var items = [
                        {
                            type: 'menuitem',
                            text: 'First Name',
                            icon: 'user',
                            onAction: function () {
                                editor.insertContent('&nbsp;||FNAME||');
                            }
                        },
                        {
                            type: 'menuitem',
                            text: 'Last Name',
                            icon: 'user',
                            onAction: function () {
                                editor.insertContent('&nbsp;||LNAME||');
                            }
                        },
                    ];
                    callback(items);
                }
            });
        }
    }

    const restoreData = async (record_id) => {
        try {
            setLoading(true);
            const recordPr = await getRecordById(record_id);
            setIsRestored(true);
            setLatestRecord(recordPr);
            setEmailBody(recordPr.email_body);
            setWysiwyg(recordPr.email_body);
            setAskForLinkWysiwyg(recordPr.ask_for_link);
            setMethodologyWysiwyg(recordPr.methodology);
            setSignatureWysiwyg(recordPr.signature);
            setRelentlessMode(recordPr.relentless_mode);
            setAskForLink(recordPr.ask_for_link);
            setMethodology(recordPr.methodology);
            setSignature(recordPr.signature);
            updateEmailSubjects(recordPr.email_subjects, recordPr.relentless_mode);
            setLoading(false);
        }  catch (error) {
            setLoading(false);
            setError(error?.message);
        }
        
    }

    const onClickRestore = async () => {
        try {
            setRecords(null);
            const res = await pressReleaseRecords(id);
            setRecords(res);
        }  catch (error) {
            setError(error?.message);
        }
    }
    
    const getImagesOnDialog = async (ref, callback) => {
        let img = [];
        const dialog = ref.current.windowManager.open(dialogConfig(callback, []));
        dialog.block('Loading');
        img = await getAllImages(id);
        dialog.unblock();
        dialog.close();
        ref.current.windowManager.open(dialogConfig(callback, img));
    };

    if (isLoading || localLoadingMenuItems) {
        return <Loading fullScreen="true" />
    }

    return (<>
        {pressReleaseLoaded ? <AutoSave
            prUpdatedAt={selectedPressRelease.updated_at}
            isRestored={isRestored}
            restoreData={restoreData} 
            emailBody={wysiwyg} 
            emailSubjects={emailSubjects} 
            relentlessMode={relentlessMode}
            signature={signatureWysiwyg}
            askForLink={askForLinkWysiwyg}
            methodology={methodologyWysiwyg}
            id={id} 
            latestRecord={latestRecord}
            records={records} /> : ''}
        {error ? <Alert error={error} ></Alert> : ''}
        {templates ? 
        <Modal props={modalPropTemplates}>
            {ReactHtmlParser(selectedTemplate['content'] ? selectedTemplate['content'] : '')}
        </Modal> : ''}
        {id && selectedPressRelease && emailSubjects?.length > 0 ?
            <>
                <Nav selectedListItem={selectedPressRelease}>
                    <div className="d-flex align-items-center">
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" role="switch" id="relentless_mode" checked={relentlessMode} onChange={(e) => onChangeRelentlessMode(!relentlessMode)} value={relentlessMode || false}/>
                            <label className="form-check-label" htmlFor="relentless_mode">Relentless mode</label>
                        </div>
                    <button className={`ms-2 btn btn-primary ${latestRecord ? '' : 'd-none'}`} data-bs-toggle="modal" data-bs-target="#all_restore" onClick={() => onClickRestore()}>Restore</button>
                    <button className={`ms-2 btn btn-primary  ${emailTemplate() ? '' : 'disabled'}`} onClick={() => savePressRelease()}>Save</button>
                    <button className={`ms-2 btn btn-primary  ${emailTemplate() ? '' : 'disabled'}`}  data-bs-toggle="modal" data-bs-target="#preview_email">Save & Send Preview</button>
                    <Modal props={modalPropPreviewEmail}>
                        <p>The changes to the press release will be saved before the preview is send!</p>
                        <p>Are you sure you want to send preview email to <b><i>{user.email}</i></b> ?</p>
                    </Modal>              
                    </div>
                </Nav>
                {emailSubjects.map((email, i) => 
                    <div className="mb-2" style={{maxWidth:"700px"}} key={i}>
                        <label><b>Email Subject</b>{i === 0 ? <i className="ms-2 bi bi-info-circle cursor-pointer" data-bs-toggle="modal" data-bs-target="#template" onClick={() => setSelectedTemplate(templates['press-release-email-subject'])}></i>: ''}</label>
                        <div className="d-flex align-items-center">
                            <input type="text" className="form-control" onChange={(e) => updateEmailSubjects(e.target.value, relentlessMode, i)} value={email || ''}></input>
                            {i === 0 && relentlessMode ? 
                                <span className="cursor-pointer" onClick={() => setAllInputValues(emailSubjects[0])}><i className="ms-3 bi bi-save2"></i></span>
                            : <></>}
                        </div>
                    </div>
                )}
                <div className="pb-5 wrapper-pr d-flex justify-content-between">
                    <div className="wrapper-editor">    
                        <div className="editor mt-3">
                            <label>
                                <b>Email body</b>
                                <i className="ms-2 bi bi-info-circle cursor-pointer" data-bs-toggle="modal" data-bs-target="#template" onClick={() => setSelectedTemplate(templates['press-release-email-body'])}></i>
                                {warningEmailBodySize ? <span className='text-danger ps-2'><i className="bi bi-exclamation-triangle pr-2"></i>The email body exceeds the maximum size and emails may fail</span> : ''}
                            </label>
                            <Editor tinymceScriptSrc={tinymcepath}
                                apiKey='cmnnd5qnqu6rfr85uibg46q9tzjq6gg9qa9hzgx4f6wo45go'
                                onInit={(evt, editor) => editorRef.current = editor}
                                onBlur={log}
                                onEditorChange={setWysiwyg}
                                initialValue={emailBody}
                                init={{...initEditor, 
                                    file_picker_callback: async function(callback, value, meta) {
                                        if (editorRef.current) {
                                            getImagesOnDialog(editorRef, callback);
                                        }
                                    }}}
                            />                          
                        </div>
                        <div className="editor mt-3">
                            <label>
                                <b>Methodology</b>
                                <i className="ms-2 bi bi-info-circle cursor-pointer" data-bs-toggle="modal" data-bs-target="#template" onClick={() => setSelectedTemplate(templates['press-release-email-methodology'])}></i>
                                </label>
                                <Editor tinymceScriptSrc={tinymcepath}                           
                                apiKey='cmnnd5qnqu6rfr85uibg46q9tzjq6gg9qa9hzgx4f6wo45go'
                                onInit={(evt, editor) => methodologyRef.current = editor}
                                onBlur={logMethodology}
                                onEditorChange={setMethodologyWysiwyg}
                                initialValue={methodology}
                                init={{...initEditor, height: 250,
                                    file_picker_callback: async function(callback, value, meta) {
                                        if (methodologyRef.current) {
                                            getImagesOnDialog(methodologyRef, callback);
                                        }
                                    }}}
                            />
                        </div>
                        <div className="editor mt-3">
                            <label>
                                <b>Ask for link</b>
                                <i className="ms-2 bi bi-info-circle cursor-pointer" data-bs-toggle="modal" data-bs-target="#template" onClick={() => setSelectedTemplate(templates['press-release-email-afl'])}></i>
                                </label>
                                <Editor tinymceScriptSrc={tinymcepath}
                                apiKey='cmnnd5qnqu6rfr85uibg46q9tzjq6gg9qa9hzgx4f6wo45go'
                                onInit={(evt, editor) => askForLinkRef.current = editor}
                                onBlur={logAskForLink}
                                onEditorChange={setAskForLinkWysiwyg}
                                initialValue={askForLink}
                                init={{...initEditor, height: 250,
                                    file_picker_callback: function(callback, value, meta) {
                                        if (askForLinkRef.current) {
                                            getImagesOnDialog(askForLinkRef, callback);
                                        }
                                    }}}
                            />
                        </div>
                        <div className="editor mt-3">
                            <label>
                                <b>Signature</b>
                                <i className="ms-2 bi bi-info-circle cursor-pointer" data-bs-toggle="modal" data-bs-target="#template" onClick={() => setSelectedTemplate(templates['press-release-email-signature'])}></i>
                                </label>
                                <Editor tinymceScriptSrc={tinymcepath}
                                apiKey='cmnnd5qnqu6rfr85uibg46q9tzjq6gg9qa9hzgx4f6wo45go'
                                onInit={(evt, editor) => signatureRef.current = editor}
                                onBlur={logSignature}
                                onEditorChange={setSignatureWysiwyg}
                                initialValue={signature}
                                init={{...initEditor, height: 250,
                                    file_picker_callback: function(callback, value, meta) {
                                        if (signatureRef.current) {
                                            getImagesOnDialog(signatureRef, callback);
                                        }
                                    }}}
                            />
                        </div>
                    </div>
                    <div className="preview ms-4 me-3">
                     <i className={`bi bi-phone ${previewDisplay === 'smartphone' ? 'active' : ''}`} onClick={() => setPreviewDisplay('smartphone')}></i>
                     <i className={`bi bi-tablet ${previewDisplay === 'tablet' ? 'active' : ''}`} onClick={() => setPreviewDisplay('tablet')}></i>
                      <div className={previewDisplay}>
                          <div className="content">
                            <p className="email-subjects">{emailSubjects[0]}</p>
                            <div className="email-body">
                                {ReactHtmlParser(wysiwyg ? wysiwyg : '')}
                            </div>
                            <div className="email-body">
                                {ReactHtmlParser(methodologyWysiwyg ? methodologyWysiwyg : '')}
                            </div>
                            <div className="email-body">
                                {ReactHtmlParser(askForLinkWysiwyg ? askForLinkWysiwyg : '')}
                            </div>
                            <div className="email-body">
                                {ReactHtmlParser(signatureWysiwyg ? signatureWysiwyg : '')}
                            </div>
                          </div>
                      </div>
                    </div>  
                </div>
            </>
            :
            ''}
    </>);
}

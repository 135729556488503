
import React from 'react';
import ReactHtmlParser from 'html-react-parser';

export default function CampaignLinks({links, site}) {
    return <>
        {links?.length > 0 ?
                <>
                <div style={{marginTop: '4.5rem'}} className='font-bold mb-2'>Links</div>
                <div className="table-responsive" style={{ maxHeight: 'calc(100vh - 20rem)'}}>
                    <table className="table table-striped table-links">
                        <thead>
                            <tr>
                                <th scope="col" className="col-table">Website</th>
                                <th scope="col" className="col-table"><div>Campaign Name</div></th>
                                <th scope="col" className="col-table"><div>Links</div></th>
                                <th scope="col" className="col-table"><div>DR</div></th>
                                <th scope="col" className="col-table"><div>Date</div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody style={{fontSize: '0.8em'}}>
                            {links.map((data, index) =>
                                <tr key={index}>
                                    <td className="col-table"><div style={{color: 'silver'}}>{site ? site: ''}</div></td>
                                    <td className="col-table"><div>{data.campaign_name}</div></td>
                                    <td className="col-table"><div><a href={data.link} rel="noreferrer" target='_blank'>{ReactHtmlParser(data.formattedLink)}</a></div></td>
                                    <td className="col-table"><div>{data.dr}</div></td>
                                    <td className="col-table"><div>{data.date}</div></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div></>
            : ''}
    </>
}

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Loading } from '../loader/Loading';
import { fetchCampaignDetails } from './clientInformationAPI';

export function ClientDetailsTab({selectedTab, data, setError, tab}) {

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [clientDetails, setClientDetails] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        return (() => {
            source.cancel('axios request canceled');
        });
    }, [selectedTab]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedTab === tab && data) {
            getCampaignDetails(data);
        }
    }, [selectedTab, data]) // eslint-disable-line react-hooks/exhaustive-deps

    const getCampaignDetails = async (client) => {
        try {
            setLoading(true);
            const res = await fetchCampaignDetails(client, source);
            setClientDetails(res);
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
                setLoading(false);
            }
        }
    }

    return (<>
        {isLoading ? <Loading fullScreen="true" /> : <>
            {selectedTab === tab ? <>
                <div className="row mt-4 ms-3 me-3">
                    {clientDetails?.length > 0 ? <div className="table-responsive">
                        <table className="table table-striped table-clients-details">
                            <thead>
                                <tr>
                                    <th scope="col" className='col-campaign-name'>Campaign Name</th>
                                    <th scope="col" className="col-campaign-lead">Campaign Lead</th>
                                    <th scope="col" className="col-status text-center">Status</th>
                                    <th scope="col" className="col-no-links text-center">No. of links</th>
                                </tr>
                            </thead>
                            <tbody>
                                {clientDetails.map((c, index) => {
                                let status = c.status.split("|")
                                return (<tr key={index}>
                                        <td className="col-campaign-name"><div>{c.campaign_name}</div></td>
                                        <td className="col-campaign-lead"><div>{c.campaign_lead}</div></td>
                                        <td className="col-status text-center">
                                            <div style={{backgroundColor: status[1], opacity:"0.3", width: '100%', height: '25px'}}></div>
                                            <div style={{position: 'absolute', top: '2px', left: '3px'}}>{status[0]}</div>
                                        </td>
                                        <td className="col-no-links text-center">{c.count_links}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div> : 'Not found'}
                    
                </div>
            </> : ''}
        </>}
    </>
    );
}

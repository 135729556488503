import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../../services/api';

export const fetchExternalCampaigns = createAsyncThunk(
  'api/external-campaigns',
  async ({perPage, page, searchTerm = null, sortDirection = null, column = null, searchName = null, searchClient = null, searchPlacementsMin = null, searchPlacementsMax = null, searchDateMin = null, searchDateMax = null, searchCategory = null,  source, loadParallel = false}) => {
      try {
        let params = new URLSearchParams({page, per_page: perPage});
        if (searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        if (sortDirection) {
            params.append('sort_direction', sortDirection);
        }
        if (column) {
            params.append('column', column);
        }
        if (searchName) {
            params.append('search_name', searchName);
        }
        if (searchClient) {
            params.append('search_client', searchClient);
        }
        if (searchPlacementsMin) {
            params.append('search_placements_min', searchPlacementsMin);
        }
        if (searchPlacementsMax) {
          params.append('search_placements_max', searchPlacementsMax);
        }
        if (searchDateMin) {
          params.append('search_date_min', searchDateMin);
        }
        if (searchDateMax) {
          params.append('search_date_max', searchDateMax);
        }
        if (searchCategory) {
          params.append('categories', searchCategory);
        }
        const response = await apiClient.get(`/api/external-campaigns?${params}`, { cancelToken: source.token });
        if (loadParallel) {
          return {...response.data, loadParallel: true};
        }
        return response.data;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw new Error(error?.response?.data?.message);
        }
    }
  }
);

export const fetchClients = createAsyncThunk(
  'api/campaign-ideas-clients',
  async (source) => {
    try {
      const response = await apiClient.get(`/api/external-campaigns/clients`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchCategories = createAsyncThunk(
  'api/campaign-ideas-categories',
  async ({source, loadParallel = false}) => {
    try {
      const response = await apiClient.get(`/api/categories`, { cancelToken: source.token });
      if (loadParallel) {
        return {data: response.data, loadParallel: true}
      }
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const createExternalCampaign = createAsyncThunk(
  'api/create-external-campaign',
  async ({source, data}) => {
    try {
      const response = await apiClient.post(`/api/external-campaigns`, { cancelToken: source.token, ...data });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

const initialState = {
  clients: null,
  externalCampaigns: [],
  sortDirection: null,
  column: null,
  searchName: '',
  searchClient: [],
  searchCategory: [],
  searchPlacementsMin: null,
  searchPlacementsMax: null,
  searchDateMin: null,
  searchDateMax: null,
  perPage: 100,
  totalRows: 0,
  loading: false,
  loadingClients: false,
  categories: [],
};

export const campaignIdeasSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },
    setColumn: (state, action) => {
      state.column = action.payload;
    },
    setSearchName: (state, action) => {
      state.searchName = action.payload;
    },
    setSearchClient: (state, action) => {
      state.searchClient = action.payload;
    },
    setSearchCategory: (state, action) => {
      state.searchCategory = action.payload;
    },
    setPlacementsMin: (state, action) => {
      state.searchPlacementsMin = action.payload;
    },
    setPlacementsMax: (state, action) => {
      state.searchPlacementsMax = action.payload;
    },
    setDateMin: (state, action) => {
      state.searchDateMin = action.payload;
    },
    setDateMax: (state, action) => {
      state.searchDateMax = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    updateExternalCampaign: (state, action) => {
      state.externalCampaigns = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    resetParams: (state, action) => {
      state.searchName = '';
      state.searchClient = [];
      state.searchCategory = [];
      state.searchPlacementsMin = null;
      state.searchPlacementsMax = null;
      state.searchDateMin = null;
      state.searchDateMax = null;
      state.perPage = 100;
      state.totalRows = 0;
      state.categories = [];
      state.externalCampaigns = [];
      state.loading = false;
      state.loadingClients = false;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchClients.pending, (state, action) => {
        state.loadingClients = true;
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.clients = action.payload?.map(m => ({'label': m.client, 'value': m.client}));
        state.loadingClients = false;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.loadingClients = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(fetchExternalCampaigns.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchExternalCampaigns.fulfilled, (state, action) => {
        state.externalCampaigns = [{id: 0, name: '', client: '', no_of_placements: 1, first_link_date: '10-10-2022'}, ...action.payload?.data];
        state.totalRows = action.payload.total;
        if (!action.payload.loadParallel) {
          state.loading = false;
        }
        
      })
      .addCase(fetchExternalCampaigns.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(fetchCategories.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload.data;
        if (!action.payload.loadParallel) {
          state.loading = false;
        }
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
      .addCase(createExternalCampaign.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createExternalCampaign.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createExternalCampaign.rejected, (state, action) => {
        state.loading = false;
        if (action.error.message !== 'axios request canceled') {
          state.error = action.error.message;
        }
      })
  },
});

export const { resetParams, setSortDirection, setColumn, setSearchName, setSearchClient, setPerPage, updateExternalCampaign, setLoading, setPlacementsMax, setPlacementsMin, setDateMax, setDateMin, setSearchCategory } = campaignIdeasSlice.actions;
export const getClients = (state) => state.campaignIdeas.clients;
export const getExternalCampaigns = (state) => state.campaignIdeas.externalCampaigns;
export const getSearchName = (state) => state.campaignIdeas.searchName;
export const getPerPage = (state) => state.campaignIdeas.perPage;
export const getSortDirection = (state) => state.campaignIdeas.sortDirection;
export const getSearchClient = (state) => state.campaignIdeas.searchClient;
export const getSearchCategory = (state) => state.campaignIdeas.searchCategory;
export const getColumn = (state) => state.campaignIdeas.column;
export const getTotalRows = (state) => state.campaignIdeas.totalRows;
export const getLoading = (state) => state.campaignIdeas.loading;
export const getCategories = (state) => state.campaignIdeas.categories;
export const getParamsForURLQuery = (state) =>  ({
  'sortDirection': state.campaignIdeas.sortDirection,
  'column': state.campaignIdeas.column,
  'searchName': state.campaignIdeas.searchName,
  'searchClient': state.campaignIdeas.searchClient?.map(c => c.value).join(),
  'searchCategory': state.campaignIdeas.searchCategory?.map(c => c.value).join(),
  'perPage': state.campaignIdeas.perPage,
  'searchPlacementsMin': state.campaignIdeas.searchPlacementsMin,
  'searchPlacementsMax': state.campaignIdeas.searchPlacementsMax,
  'searchDateMin': state.campaignIdeas.searchDateMin,
  'searchDateMax': state.campaignIdeas.searchDateMax
});
export const getLoadingClients = (state) => state.campaignIdeas.loadingClients;

export default campaignIdeasSlice.reducer;

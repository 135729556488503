import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Loading } from '../loader/Loading';
import {
    skipEmailToSend,
    fetchCampaignsEmails
} from './campaignsSlice';
import axios from 'axios';
import Modal from '../../components/Modal';
import Alert from '../../components/Alert';
import { Tooltip } from '../../components/Tooltip';
import Wrapper from '../../components/Wrapper';
import './tableCampaign.scss'
import { sleep } from '../../help/help';

export function TableStartedCampaign({clickTracking, selectedCampaign}) {
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const { id } = useParams();
    const [error, setError] = useState('');
    const [emailSkip, setEmailSkip] = useState(null);
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const [data, setData] = useState([]);
    const [emailsCount, setEmailsCount] = useState(0);

    useEffect(() => {
        getCampaign(id);
        return (() => {
            source.cancel('axios request canceled');
            setData([]);
            setEmailsCount(0);
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getCampaign = async (id) => {
        setLoading(true);
        try {
            const res = await dispatch(fetchCampaignsEmails({ id, source })).unwrap();
            setData(res.emails);
            setEmailsCount(res.count)
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setLoading(false);
                setError(error?.message);
            }
        }
    }

    const getStatusByStep = (statuses, step) => {
        return statuses.find(s => s.step === step);
    }

    const setBorder = (idx = 0) => {
        const lengthSteps = selectedCampaign.steps.length;
        if (lengthSteps > 1 && !selectedCampaign.steps[idx].id) {
            return 'border-right-grey'
        }
        return lengthSteps > 1 && lengthSteps - 1 !== idx && selectedCampaign.steps[idx].id ? 'border-right ' : ''
    }

    const setInactiveStep = (idx = 0) => {
        return !selectedCampaign.steps[idx].id ? 'opacity-50' : ''
    }

    const getHeaderDateAndTime = (date) => {
        const hour = ("0" + date.getHours()).slice(-2);
        const minutes = ("0" + date.getMinutes()).slice(-2);
        return weekday[date.getDay()] + ',' + hour + ':' +minutes;
    }

    const hasComplaint = (statuses) => {
        return statuses.find(s => s?.complaint === 1);
    }

    const formatDate = (date) => {
        const dateFormat = new Date(date)
        return new Intl.DateTimeFormat('en-GB',{'dateStyle':'medium', 'timeStyle':'medium'}).format(dateFormat)
    }

    const openedDates = (dates) => {
        const localDates = dates.map(d => formatDate(d));
        return localDates.join(' <br/> ');
    }

    const toggle = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const currentTarget = event.currentTarget;
        currentTarget.classList.add('loading-item');
        await sleep(1);
        const itemParent = currentTarget.parentElement?.parentElement?.parentElement;
        if (itemParent.classList.contains('show-all')) {
            currentTarget.parentElement.parentElement.parentElement.classList.remove('show-all');
        }
        let parent = currentTarget.parentElement.parentElement;
        let items = parent.querySelector('.items');
        if (items.classList.contains('hide')) {
            items.classList.remove('hide');
            if (itemParent) {
                currentTarget.parentElement.parentElement.parentElement.classList.add('show-all');
            }
        } else {
            items.classList.add('hide');
            items.classList.remove('show-all');
        }
        toggleIcon(currentTarget, items);
        await sleep(1);
        currentTarget.classList.remove('loading-item');
    }

    const toggleAllChildren = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        const currentTarget = event.currentTarget.parentElement;
        currentTarget.classList.add('loading-item');
        await sleep(1);
        let parent =  currentTarget.parentElement.parentElement;
        let items = parent.querySelectorAll('.items');
        if (items[0].classList.contains('show-all')) {
            items[0].classList.remove('show-all');
            items.forEach(i => {
                toggleIcon(i.parentElement.children[0].children[0], items[0]);
                i.classList.add('hide');
            });
            items[0].classList.remove('hide');
        } else {
            items[0].classList.add('show-all');
            items.forEach(i => {
                toggleIcon(i.parentElement.children[0].children[0], items[0]);
                i.classList.remove('hide');
            });
            items[0].classList.remove('hide');
        }
        toggleIcon(currentTarget, items[0]);
        await sleep(1);
        currentTarget.classList.remove('loading-item');
    }

    const toggleIcon = (currentTarget, item) => {
        if (currentTarget && currentTarget.children[0]) {
            if (!item.classList.contains('hide')) {
                currentTarget.children[0].classList.remove('bi-chevron-right');
                currentTarget.children[0].classList.add('bi-chevron-down');
            } else {
                currentTarget.children[0].classList.remove('bi-chevron-down');
                currentTarget.children[0].classList.add('bi-chevron-right');
            }
        }
    }

    const modalSkipCampaignEmailProp = {
        title: emailSkip?.data.skip ? 'Unskip email' : 'Skip email',
        target: 'skip_email',
        nameSaveButton: emailSkip?.data.skip ? 'Unskip email' : 'Skip email',
        add: async () => skipEmailFromCampaign(emailSkip?.data.id, emailSkip.idx, emailSkip?.data.skip ? 0 : 1, emailSkip?.typeOfEmail, emailSkip?.group)
    }

    const skipEmailFromCampaign = async (id, index, skip, typeOfEmail, group) => {
        setLoading(true);
        try {
            await dispatch(skipEmailToSend({id, skip})).unwrap();
            let updateEmails = JSON.parse(JSON.stringify(data));
            if (group) {
                updateEmails[typeOfEmail][group][index]['skip'] = skip;
            } else {
                updateEmails[typeOfEmail][index]['skip'] = skip;   
            }
            setData(updateEmails);
            setLoading(false);
        } catch (e) {
            setLoading(false);
            setError(error?.message);
        }
    }

    const table = (data, typeOfEmail = null, group = null) => (
        <table className="table table-campaign">
            <tbody>
                {data?.map((email, index) => (
                    <tr key={index} className={`show ${hasComplaint(email.statuses) ? 'table-danger' : ''}`}>
                        <td className="stop-email">
                            {email.skip ?
                                    <Tooltip text={'Skipped'}>
                                        <i className="bi bi-stop text-danger" data-bs-toggle={`${!selectedCampaign.finish ? 'modal' : ''}`} data-bs-target="#skip_email" onClick={() => setEmailSkip({data: email, idx: index, typeOfEmail: typeOfEmail, group: group})}></i>
                                    </Tooltip> : 
                                    <Tooltip text={`${!selectedCampaign.finish ? 'Click to skip email' : ''}`}>
                                        <i className="bi bi-stop text-success" data-bs-toggle={`${!selectedCampaign.finish ? 'modal' : ''}`} data-bs-target="#skip_email" onClick={() => setEmailSkip({data: email, idx: index, typeOfEmail: typeOfEmail, group: group})}></i>
                                    </Tooltip>}
                        </td>
                        <td className="name-col"><div>{email.first_name}{email.last_name  ? ', ' + email.last_name : ''}</div></td>
                        <td  className={`email-col ${setBorder()}`}><div>{email.email}</div></td>
                        {selectedCampaign.steps.map((s, i) => {
                            let emailStatus = getStatusByStep(email.statuses, s.step)
                            return (<Wrapper key={i}>
                                <td className="text-center col-limited-size">
                                {emailStatus?.delivered && !emailStatus?.blocked ?
                                    <Tooltip text={'Delivered: <br/>' + formatDate(emailStatus?.delivered_date)}>
                                        <i className="bi bi-check campaigns-icons text-success"></i>
                                    </Tooltip> : ''}
                                {emailStatus?.rejected ? <i className="bi bi-x campaigns-icons text-danger"></i> : ''}
                                {emailStatus?.failed ? <i className="bi bi-x campaigns-icons text-danger"></i> : ''}
                                {emailStatus?.blocked ? 
                                    <Tooltip text={`Blocked because:<br> ${Array.isArray(emailStatus.blocked) ? emailStatus.blocked.join(',') : emailStatus.blocked}`}>
                                <i className="bi bi-circle-fill"></i>
                                    </Tooltip> : ''}
                                </td>
                                <td  className="text-center col-limited-size">{emailStatus?.opened ?
                                    <Tooltip text={'Opened: <br/>' + openedDates(emailStatus?.opened_dates)}> 
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <span className="badge rounded-pill bg-primary">{emailStatus?.opened_count}</span>
                                        </div>
                                    </Tooltip> : ''}
                                </td>
                                { clickTracking ? <td className="text-center col-limited-size">{emailStatus?.clicked ? <i className="bi bi-check campaigns-icons text-success"></i> : ''}</td> :<></>}
                                <td className="text-center col-limited-size">{emailStatus?.bounce ? <i className="bi bi-x campaigns-icons text-danger"></i> : ''}</td>
                                <td className={`text-center col-limited-size ${setBorder(i)}`}>{emailStatus?.unsubscribed ? <i className="bi bi-x campaigns-icons text-danger"></i> : ''}</td>
                            </Wrapper>)})
                        }
                    </tr>          
                ))}
            </tbody>
        </table>
    )

    if (isLoading) {
        return <Loading fullScreen="true" />
    }

    return <>
        {error ? <Alert error={error} ></Alert> : ''}
        <Modal props={modalSkipCampaignEmailProp}>
            {emailSkip?.data ? `Are you sure you want to ${emailSkip.data.skip ? 'unskip' : 'skip'} the email ${emailSkip.data.email}, ${emailSkip.data.first_name}, ${emailSkip.data?.last_name} ?` : ''}
        </Modal>
        <table className="table table-campaign sticky-header-table">
            <thead>
                {selectedCampaign.steps.length > 1 ?
                <>
                <tr>
                    <td className="stop-email"><span>{emailsCount} journalists</span></td>
                    <td className="name-col"></td>
                    <td className={`email-col ${setBorder()}`}></td>
                    {
                    selectedCampaign.steps.map((s, idx) => {
                        let created = new Date(s.add_date);
                        return (<td className={`text-center weekend ${setBorder(idx)} ${setInactiveStep(idx)}`} key={idx} colSpan={clickTracking ? '5' : '4'}>{getHeaderDateAndTime(created)}</td>)
                    }) 
                    }
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td className={setBorder()}></td>
                    {
                    selectedCampaign.steps.map((s, idx) =>
                        <td className={`text-center fw-bold ${setBorder(idx)} ${setInactiveStep(idx)}`} key={idx} colSpan={clickTracking ? '5' : '4'}>{'S' + (idx + 1)}</td>
                    )  
                    }
                </tr></> : <><tr><td className="stop-email"><span>{emailsCount} journalists</span></td></tr></>}
                <tr>
                    <th scooe="col" className="stop-email"></th>
                    <th scope="col" className="name-col">Name</th>
                    <th scope="col"  className={`email-col ${setBorder()}`}>Email</th>
                    {selectedCampaign.steps.map((v, i) => 
                        <Wrapper key={i}>
                            <th scope="col" className={`text-center col-limited-size ${setInactiveStep(i)}`}>
                                <Tooltip text='Delivered'><div>D</div></Tooltip>
                            </th>
                            <th scope="col" className={`text-center col-limited-size ${setInactiveStep(i)}`}>
                                <Tooltip text='Opened'><div>O</div></Tooltip>
                            </th>
                            {
                                clickTracking ?
                                <th scope="col" className={`text-center col-limited-size ${setInactiveStep(i)}`}>
                                <Tooltip text='Clicked'><div>C</div></Tooltip>
                                </th> : <></>
                            }
                            <th scope="col" className={`text-center col-limited-size ${setInactiveStep(i)}`}>
                                <Tooltip text='Bounce'><div>B</div></Tooltip>
                            </th> 
                            <th scope="col" className={`text-center col-limited-size ${setBorder(i)} ${setInactiveStep(i)}`}>
                                <Tooltip text='Unsubscribed'><div>U</div></Tooltip>
                            </th>
                        </Wrapper>
                    )}
                    
                </tr>
            </thead>
        </table>
        {data && Object.keys(data)?.length > 0 && Object.keys(data).map((typeOfEmail, idx) => {
            let isArray = Array.isArray(data[typeOfEmail]);
            return <Wrapper key={idx}>
                <div className="lists">
                    <div className="toggle email-group">
                        <span className='box-toggle' onClick={(e) => toggle(e)}>
                            <i className="bi bi-chevron-down me-2"></i>
                            {typeOfEmail}
                            {typeOfEmail === 'From Lists' ? <i title="Toggle all items" className="ms-3 bi bi-chevron-double-down me-2" onClick={(e) => toggleAllChildren(e)}></i> : ''}
                            <div className="spinner-border text-primary spinner-table" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </span>
                    </div>
                    <div className="items show-all">
                        {isArray ?
                            <>{table(data[typeOfEmail], typeOfEmail, null)}</> :
                            <>
                                {typeOfEmail && data[typeOfEmail] && Object.keys(data[typeOfEmail])?.length > 0 && Object.keys(data[typeOfEmail]).map((group, index) => {
                                    const emailGrouped = data[typeOfEmail][group];
                                    const titleGroup = group.split('||');
                                    return (<div key={index} className={`list-${index}`}>
                                        <div className="toggle email-subgroup">
                                            <span className='box-toggle' onClick={(e) => toggle(e)}>
                                                <i className="bi bi-chevron-down me-2"></i>
                                                {titleGroup[0]}
                                                <div className="spinner-border text-primary spinner-table" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                                <span className='ms-3' style={{color: 'silver'}}>{titleGroup[1]}</span>
                                            </span>
                                        </div>
                                        <div className="items"><>{table(emailGrouped, typeOfEmail, group)}</></div>
                                    </div>)
                                })}
                            </>
                        }
                    </div>
                </div>
            </Wrapper>
        })}
    </>
}
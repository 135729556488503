import React, { useState, useRef, useEffect } from 'react';
import Modal from '../../components/Modal';
import Alert from '../../components/Alert';
import { addWorkFlowItem, deleteWorkFlowItem, editWorkFlowItem, fetchUsers } from './workFlowAPI';
import axios from 'axios';
import './workFlow.scss'
import { Loading } from '../loader/Loading';
import { DataCol } from './DataCol';
import { PressReleaseCol } from './PressReleaseCol';
import { ClientsCol } from './ClientsCol';
import { IdeaCol } from './IdeaCol';
import { PitchCol } from './PitchCol';
import ReactHtmlParser from 'html-react-parser';
import { Tooltip } from '../../components/Tooltip';
import { Status } from './Status';
import { useNavigate, useLocation } from 'react-router';

export function WorkFlowTable({dataTable, id , type}) {

    const modal_ref = useRef(null);
    const closeBtnRef = useRef(null);
    const [selectedType, setSelectedType] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isEditLoading, setEditLoading] = useState(false);
    const [error, setError] = useState('');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [dataWorkFlowTable, setDataWorkFlowTable] = useState(dataTable);
    const [remount, setRemount] = useState(0);
    const [users, setUsers] = useState(null);
    const statusBgColor = [{id: 0, color:'white'}, {id: 1, color:'rgb(255 231 97 / 13%)'}, {id: 100, color: 'rgb(97 255 97 / 7%)'}];
    const listTypes = {'suitable-for': 'Suitable for clients', 'campaign-name': 'Campaign Name', 'idea': 'Idea', 'data': 'Data', 'press-release': 'Press Release', 'pitch': 'Pitch'}
    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        if (dataWorkFlowTable?.length > 0) {
            getUsers();
        }
        if (dataWorkFlowTable?.length > 0 && id && type) {
            let item = dataWorkFlowTable.find(d => parseInt(d.id) === parseInt(id));
            if (item) {
                editCurrentItem(listTypes[type], item, false);
            } else {
                navigateWorkFlow();
            }
        }
        const myModal = document.getElementById('work_flow_modal');
        myModal.addEventListener('hidden.bs.modal', navigateWorkFlow);
        return (() => {
            // source.cancel('axios request canceled');
            setDataWorkFlowTable(null);
            myModal.removeEventListener('hidden.bs.modal', navigateWorkFlow);
            setUsers(null);
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const navigateWorkFlow = () => {
        let searchParams = new URLSearchParams(location.search);
        navigate(`/work-flow?${searchParams}`);
    }

    const propModal = {
        title: 'Edit',
        target: 'work_flow_modal',
        nameSaveButton: 'Save',
        size: 'xl',
        add: async () => {
            try {
            setEditLoading(true)
            const res = await editWorkFlowItem(selectedItem.id, selectedItem);
            let data = [...dataWorkFlowTable];
            let indexOfEl = data.findIndex(d => d.id === res.id);
            data[indexOfEl] = res;
            setDataWorkFlowTable(data);
            setEditLoading(false);
            }  catch (error) {
                setEditLoading(false)
                setError(error?.message);
                editCurrentItem(selectedType, selectedItem);
            }
        }
    }

    const addWFData = async (data) => {
        try {
            setEditLoading(true);
            let res = await addWorkFlowItem(data);
            if (!res.client) res.client = [];
            if (!res.idea_user) res.idea_user = [];
            if (!res.data) res.data = [];
            if (!res.data_user) res.data_user = [];
            if (!res.press_release) res.press_release = [];
            if (!res.press_release_user) res.press_release_user = [];
            if (!res.pitch_user) res.pitch_user = [];
            setDataWorkFlowTable([res, ...dataWorkFlowTable]);
            setEditLoading(false);
            editCurrentItem('Idea', res);
        } catch (error) {
            setEditLoading(false)
            setError(error?.message);
        }
    }

    const onEnterCampaignName = async (val) => {
        if (val && val.length > 0) {
            setSelectedItem(null);
            await addWFData({campaign_name: val});
            let name = document.getElementById('campaign_name');
            if (name) name.innerHTML = '';
        }
    }

    const editCurrentItem = async (type, data, nav = true) => {
        if (nav) {
            let elIdx = Object.values(listTypes).findIndex(d => d === type);
            let keyType = Object.keys(listTypes)[elIdx];
            let searchParams = new URLSearchParams(location.search);
            navigate(`/work-flow/${data.id}/${keyType}?${searchParams}`);
        }
        setRemount(remount + 1);
        setSelectedItem(data);
        setSelectedType(type);
        document.getElementById('exampleModalLabel').innerHTML = `${type} - ${data.campaign_name}`;
        modal_ref.current.click();
    }

    const getUsers = async () => {
        try {
            setLoading(true);
            const res = await fetchUsers(source);
            setUsers(res.map(u => ({label: u.name, value: u.id})));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setError(error?.message)
        }
    }

    const deleteWFItem = async () => {
        try {
           
            if (window.confirm('Do you want to delete this item?')) {
                setLoading(true);
                await deleteWorkFlowItem(selectedItem.id);
                closeBtnRef.current.click();
                let id = selectedItem.id;
                setSelectedItem(null);
                setDataWorkFlowTable(dataWorkFlowTable.filter(d => d.id !== id));
                setLoading(false);
                

            }
        } catch (error) {
            setLoading(false);
            setError(error?.message);
        }
    }

    const statuses = () => {
        let template = null;
        switch(selectedType) {
            case 'Idea':
              template = <div className='status-wrapper'><label>Idea status</label><Status selectedItem={selectedItem} setSelectedItem={setSelectedItem} statusKey='idea_status' key={remount}></Status></div>
              break;
            case 'Data':
                template = <div  className='status-wrapper'><label>Data status</label><Status selectedItem={selectedItem} setSelectedItem={setSelectedItem} statusKey='data_status' key={remount}></Status></div>
              break;
            case 'Press Release':
            template = <div  className='status-wrapper'><label>Press Release status</label><Status selectedItem={selectedItem} setSelectedItem={setSelectedItem} statusKey='press_release_status' key={remount}></Status></div>
              break;
            case 'Pitch':
            template = <div  className='status-wrapper'><label>Pitch status</label><Status selectedItem={selectedItem} setSelectedItem={setSelectedItem} statusKey='pitch_status' key={remount}></Status></div>
                break;
            case 'Campaign Name':
            template = <div className='text-danger me-auto' style={{fontSize: '0.7rem', opacity: '0.6'}} onClick={() => deleteWFItem()}>Delete</div>
                break;
            default:
              template = null;
          }
        return template;
    };


    const modalCloseState = () => {
        if (!isUserAssigned()) {
            return false;
        }
        if (selectedItem) {
            const data = dataWorkFlowTable.find(d => d.id === selectedItem.id);
            if (!data.client) data.client = [];
            if (!data.idea_user) data.idea_user = [];
            if (!data.data) data.data = [];
            if (!data.data_user) data.data_user = [];
            if (!data.press_release) data.press_release = [];
            if (!data.press_release_user) data.press_release_user = [];
            if (!data.pitch_user) data.pitch_user = [];
            if (JSON.stringify(data) !== JSON.stringify(selectedItem)) {
                return false;
            }
            return true;
        }
    }

    const isUserAssigned = () => {
        if (selectedItem) {
            if (selectedType === 'Idea') {
                return selectedItem.idea_user?.length > 0;
            }
            if (selectedType === 'Data') {
                return selectedItem.data_user?.length > 0;
            }
            if (selectedType === 'Press Release') {
                return selectedItem.press_release_user?.length > 0;
            }
            if (selectedType === 'Pitch') {
                return selectedItem.pitch_user?.length > 0;
            }
            return true;
        }
    };

    if (isEditLoading) {
        return <Loading fullScreen="true" />
    }

    return (<>
        {error ? <Alert error={error} ></Alert> : ''}
        {dataWorkFlowTable ? <>
            <button className="d-none" data-bs-toggle="modal" data-bs-target="#work_flow_modal" ref={modal_ref}></button>
            <Modal props={propModal} footer={selectedItem ? statuses() : ''} clickOutside={false} canClose={modalCloseState()} scrollable={true} closeBtnRef={closeBtnRef} userAssigned={isUserAssigned()}>
                {isLoading || !selectedItem || !users ? <Loading fullScreen="true" /> : <>
                    {selectedType === 'Suitable for clients' || selectedType === 'Campaign Name' ? 
                        <>
                            <label>Campaign Name</label>
                            <input type="text" className="form-control mb-2" onChange={(e) => setSelectedItem({...selectedItem, campaign_name: e.target.value})} value={selectedItem?.campaign_name ? selectedItem.campaign_name : ''}></input> 
                            <ClientsCol selectedItem={selectedItem} setSelectedItem={setSelectedItem} key={remount} />
                        </>
                        : <></>}
                    {selectedType === 'Idea' ?
                        <IdeaCol selectedItem={selectedItem} setSelectedItem={setSelectedItem} users={users} key={remount}/> : <></>}
                    {selectedType === 'Data' ? 
                        <DataCol selectedItem={selectedItem} setSelectedItem={setSelectedItem} users={users} key={remount}/> : <></>}
                    {selectedType === 'Press Release' ?
                        <PressReleaseCol selectedItem={selectedItem} setSelectedItem={setSelectedItem} users={users} key={remount}/> : <></>}
                    {selectedType === 'Pitch' ?
                        <PitchCol selectedItem={selectedItem} setSelectedItem={setSelectedItem} users={users} key={remount}/> : <></>}
                
                </>}
                
            </Modal>
        </> : <></>}
        
        {dataWorkFlowTable ?
            <div className="row work-flow">
                <div className="table-responsive">
                    <table className="table table-work-flow">
                        <thead>
                            <tr>
                                <th style={{width:'170px', border: 'none'}}></th>
                                <th style={{border: 'none'}} className="text-center opacity-50">Ideation</th>
                                <th></th>
                                <th className="text-center opacity-50">Analyst</th>
                                <th className="text-center opacity-50">Writer</th>
                                <th className="text-center opacity-50">Pitcher</th>
                            </tr>
                            <tr>
                                <th scope="col" className="text-center">Suitable for</th>
                                <th scope="col" className="text-center">Campaign Name</th>
                                <th className="text-center">Idea explanation</th>
                                <th className="text-center">Data brief</th>
                                <th className="text-center">Press Release brief</th>
                                <th className="text-center">Pitching brief</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                                <td><div className='text-center'><input type="text" id="campaign_name" onKeyPress={(e) => e.key === 'Enter' && onEnterCampaignName(e.target.value)} placeholder="Add new..."></input></div></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                            {dataWorkFlowTable?.map((data, index) =>
                                <tr key={index}>
                                    <td style={{backgroundColor: statusBgColor.find(s => s.id === data.idea_status)?.color}} onClick={() => editCurrentItem('Suitable for clients', dataWorkFlowTable[index])}>{data?.client ? <Tooltip text={data.client.map(d => d.name).join(', ')}><div>{data.client.map(d => d.name).join(', ')}</div></Tooltip> : <span style={{color: 'silver'}} >Client</span>}</td>
                                    <td style={{backgroundColor: statusBgColor.find(s => s.id === data.idea_status)?.color}} onClick={() => editCurrentItem('Campaign Name', dataWorkFlowTable[index])}>
                                        <div className='d-flex justify-content-between'>
                                            <div>{data.campaign_name}</div>
                                            {data.user_name ? <Tooltip text={`Created by: ${data.user_name}`}><i className="ms-1 bi bi-person-fill"></i></Tooltip> : '' }
                                        </div>
                                        </td>
                                    <td style={{backgroundColor: statusBgColor.find(s => s.id === data.idea_status)?.color}} onClick={() => editCurrentItem('Idea', dataWorkFlowTable[index])}>
                                        <div className='d-flex justify-content-between'>
                                        {data.idea_description ? <div>{ReactHtmlParser(data?.idea_description ? data.idea_description : '')}</div> : <div style={{color: 'silver'}}>Idea</div> }
                                        {data.idea_user?.length > 0 ? <Tooltip text={data.idea_user.map(u => u.name).join(', ')}><i className="ms-1 bi bi-person-fill"></i></Tooltip> : '' }
                                        </div>
                                    </td>
                                    <td style={{backgroundColor: statusBgColor.find(s => s.id === data.data_status)?.color}} onClick={() => editCurrentItem('Data', dataWorkFlowTable[index])}>
                                        <div className='d-flex justify-content-between'>
                                            {data.data_description ? <div>{ReactHtmlParser(data?.data_description ? data.data_description : '')}</div> : <div style={{color: 'silver'}}>Data</div> }
                                            {data.data_user?.length > 0 ? <Tooltip text={data.data_user.map(u => u.name).join(', ')}><i className="ms-1 bi bi-person-fill"></i></Tooltip> : '' }
                                        </div>
                                    </td>
                                    <td style={{backgroundColor: statusBgColor.find(s => s.id === data.press_release_status)?.color}} onClick={() => editCurrentItem('Press Release', dataWorkFlowTable[index])}>
                                        <div className='d-flex justify-content-between'>
                                            {data.press_release_description ? <div>{ReactHtmlParser(data?.press_release_description ? data.press_release_description : '')}</div> : <div style={{color: 'silver'}}>Press Release</div> }
                                            {data.press_release_user?.length > 0 ? <Tooltip text={data.press_release_user.map(u => u.name).join(', ')}><i className="ms-1 bi bi-person-fill"></i></Tooltip> : '' }
                                        </div>
                                    </td>
                                    <td style={{backgroundColor: statusBgColor.find(s => s.id === data.pitch_status)?.color}} onClick={() => editCurrentItem('Pitch', dataWorkFlowTable[index])}>
                                        <div className='d-flex justify-content-between'>
                                            {data.pitch_description ? <div>{ReactHtmlParser(data?.pitch_description ? data.pitch_description : '')}</div> : <div style={{color: 'silver'}}>Pitch</div> }
                                            {data.pitch_user?.length > 0 ? <Tooltip text={data.pitch_user.map(u => u.name).join(', ')}><i className="ms-1 bi bi-person-fill"></i></Tooltip> : '' }
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        : ''}
    </>)
}
import React, { useEffect, useState } from 'react';
import Alert from '../../components/Alert';
import { fetchEventsUsers, fetchUserCampaigns } from './eventsAPI';
import { Loading } from '../loader/Loading';
import './events.scss';
import { EventUser } from './EventUser';
import { EventUserCampaign } from './EventUserCampaign';
import axios from 'axios';

export function Events() {
  const [error, setError] = useState(null);
  const [eventsUsers, setEventsUsers] = useState(null);
  const [userCampaigns, setUserCampaigns] = useState(null);
  const [filter, setFilter] = useState('all');
  const [isLoading, setLoading] = useState(false);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const user = JSON.parse(localStorage.getItem('loggedIn'))?.user;

  useEffect(() => {
    document.title = 'Home - JournoResearch';
    setEventsUsers([]);
    paralelLoadData();
    return (() => {
      source.cancel('axios request canceled');
      setEventsUsers(null);
      setUserCampaigns(null);
      setFilter('all');
    });

  }, [filter]) // eslint-disable-line react-hooks/exhaustive-deps

  const paralelLoadData = async () => {
    await Promise.all([getEventsUsers(), getUserCampaigns()]);
    setLoading(false);
  }

  const getEventsUsers = async () => {
    try {
        setLoading(true);
        const users = await fetchEventsUsers(source);
        setEventsUsers(users);
    } catch (error) {
      if (error?.message !== 'axios request canceled') {
        setError(error?.message);
        setLoading(false);
      }
    }
  }

  const getUserCampaigns = async () => {
    try {
      setLoading(true);
      const campaigns = await fetchUserCampaigns(source);
      setUserCampaigns(campaigns);
    } catch (error) {
      if (error?.message !== 'axios request canceled') {
        setError(error?.message);
        setLoading(false);
      }
    }
  }

  return (
    <div className="row events">
      {isLoading ? <Loading fullScreen="true" /> : <>
        <div className="mb-2 col-md-6 col-xs-12">
            <div className="d-flex align-items-center flex-wrap">
              <h2>Latest Activity</h2>
              <div className="wrapper-btn-filter ms-2 flex-wrap">
                  <button className='btn btn-primary ms-2 mb-1' onClick={() => setFilter('all')}>All</button>
                  <button className='btn btn-primary ms-2 mb-1' onClick={() => setFilter('campaigns')}>Campaigns</button>
                  <button className='btn btn-primary ms-2 mb-1' onClick={() => setFilter('press_releases')}>Press Releases</button>
              </div>
            </div>
            <div className="logs">
              <div className="wrapper-card mb-4">
                  { eventsUsers?.map((eventUser, idx)=>
                      <div key={idx} className="mt-4">
                      <EventUser event_user={eventUser} filter={filter}/>
                      </div>
                  )}
              </div>
            </div>
        </div>
        <div className="mb-2 col-md-6 col-xs-12">
            <h2>Your Recent Sends</h2>
            <div className="logs recent-sents">
              <div className="wrapper-card mb-4">
                    { userCampaigns?.map((campaign, idx)=>
                        <div key={idx} className="mt-4">
                          {idx === 0 ? <div className="card-title">{user.name}</div> : ''}
                          <EventUserCampaign userCampaign={campaign} key={idx}/>
                        </div>
                    )}
              </div>
            </div>
        </div>
      </>}
      {error ? <Alert error={error} ></Alert> : ''}
    </div>
  );
}


import React from 'react';

export default function Alert({ error }) {
    return (
        <div className="toast-container position-absolute p-3 bottom-0 start-50 translate-middle-x" id="toastPlacement">
            <div className={`text-white bg-danger toast fade align-items-center hide ${error.length > 0 ? 'show' : ''}`} role="alert" aria-live="assertive" aria-atomic="true">
                <div className="d-flex">
                    <div className="toast-body">
                        Something went wrong: {error}
                    </div>
                    <button type="button" className="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                </div>
            </div>
        </div>)
}

import React from "react";

export default function fCampaignTimeline({ campaign }) {

  return (
    <>
    <div className="progressbar" style={{height: '35px', margin: '1.5rem'}}>
        <div className="indicator" style={{left: campaign.current_progress_percentage + '%'}}></div>
        <div className="indicator-progress" style={{width: campaign.current_progress_percentage + '%'}}>
           {/* <span className="total-links" title="Progress">{parseInt(campaign.current_progress_percentage) + '%;' }</span> */}
        </div>
        <div className="tick" style={{left: campaign.current_progress_percentage + '%'}}>
            <span className="absolute text-slate-400 current-date">Today ({campaign?.current_date})</span>
            <span className="line">

            </span>
            <span className="tick-status">{ campaign.status_campaign }
            </span>
        </div>
        <div className="ideation" style={{width: campaign.ideation_percentage + '%'}}>
            <span className="absolute item-date">{ campaign.short_start_date }</span>
            <span>Ideation</span>
            <span className="line">
            </span>
        </div>
        <div className="execution" style={{width: campaign.execution_percentage + '%', left: parseInt(campaign.ideation_percentage) + '%'}}>
            <span className="absolute item-date">{ campaign.execution_start_date }</span>
            <span>Execution</span>
            <span className="line">
            </span>
        </div>
        <div className="pitching" style={{width: campaign.pitching_percentage + '%', left: (parseInt(campaign.ideation_percentage) + parseInt(campaign.execution_percentage))  + '%'}}>
            <span className="absolute item-date">{campaign.pitching_start_date }</span>
            <span>Pitching</span>
            <span className="line">
            </span>
        </div>
        <div className="links" style={{width: campaign.links_percentage + '%', left: (parseInt(campaign.ideation_percentage) + parseInt(campaign.execution_percentage) + parseInt(campaign.pitching_percentage))  + '%'}}>
            <span className="absolute item-date">{ campaign.links_start_date }</span>
            <span className="absolute item-last-date">{ campaign.short_end_date }</span>
            <span>Links</span>
            <span className="line">
            </span>
        </div>
    </div>
    </>
  );
}

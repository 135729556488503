import React, { useEffect, useState } from 'react';
import Alert from '../../components/Alert';
import { fetchLatestCampaings, fetchLatestPressReleases } from './eventsAPI';
import { Loading } from '../loader/Loading';
import './events.scss';
import axios from 'axios';
import { LatestActivityCampaigns } from './LatestActivityCampaigns';
import { LatestActivityPressReleases } from './LatestActivityPressReleases';

export function LatestActivity() {
  const [error, setError] = useState(null);
  const [latestCampaigns, setLatestCampaings] = useState(null);
  const [latestPressReleases, setLatestPressReleases] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();

  useEffect(() => {
    document.title = 'Home - JournoResearch';
    paralelLoadData();
    return (() => {
      source.cancel('axios request canceled');
      setLatestCampaings(null);
      setLatestPressReleases(null);
      setLoading(false);
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const paralelLoadData = async () => {
    await Promise.all([getLatestCampaigns(), getLatestPressReleases()]);
    setLoading(false);
  }

  const getLatestCampaigns = async () => {
    try {
        setLoading(true);
        const campaigns = await fetchLatestCampaings(source);
        setLatestCampaings(campaigns);
    } catch (error) {
      if (error?.message !== 'axios request canceled') {
        setError(error?.message);
        setLoading(false);
      }
    }
  }

  const getLatestPressReleases = async () => {
    try {
      setLoading(true);
      const pr = await fetchLatestPressReleases(source);
      setLatestPressReleases(pr);
    } catch (error) {
      if (error?.message !== 'axios request canceled') {
        setError(error?.message);
        setLoading(false);
      }
    }
  }


  return (
    <div className="row latest-activity">
      {error ? <Alert error={error} ></Alert> : ''}
      {isLoading ? <Loading fullScreen="true" /> : <>
        <div className="mb-2 col-xxl-8 col-xl-12 col-xs-12">
            <div className="d-flex align-items-center flex-wrap">
              <h2>Latest Campaigns</h2>
            </div>
            <div className="logs">
              <div className="wrapper-card mb-4">
                <LatestActivityCampaigns data={latestCampaigns} />
              </div>
            </div>
        </div>
        <div className="mb-2 col-xxl-4 col-xl-12 col-xs-12">
            <h2>Latest Press Releases</h2>
            <div className="logs recent-sents">
              <div className="wrapper-card mb-4">
                <LatestActivityPressReleases data={latestPressReleases} />
              </div>
            </div>
        </div>
      </>}
    </div>
  );
}

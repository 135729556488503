import React, { useEffect, useState } from "react";
import { Loading } from "../loader/Loading";
import { DoughnutChart } from "../../components/DoughnutChart";
import apiClient from "../../services/api";
import axios from "axios";
import { Line } from "react-chartjs-2";

export function MailsStatistics() {
  const [isLoading, setLoading] = useState(true);
  const [statistics, setStatistics] = useState(null);
  const [resolution, setResolution] = useState('days');
  const cancelToken = axios.CancelToken;
  const source = cancelToken.source();
  const getStatistics = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get(`/api/mails-statistics?resolution=${resolution}`, {
        cancelToken: source.token,
      });
      // The value we return becomes the `fulfilled` action payload
      setStatistics(response.data);
      setLoading(false);
    } catch (error) {
      if (error?.message !== "axios request canceled") {
        setLoading(false);
      }
    }
  };

  const formatDate = (date) => {
    const dateFormat = new Date(date);
    return new Intl.DateTimeFormat("en-GB", {
      dateStyle: "medium",
      timeStyle: "medium",
    }).format(dateFormat);
  };

  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const formatTime = (time) => {
    const data = new Date(time);
    const hour = ("0" + data.getHours()).slice(-2);
    const minutes = ("0" + data.getMinutes()).slice(-2);
    const day = data.getDay();
    if (resolution === 'days') {
      return weekday[day];
    }
    return weekday[day] + "," + hour + ":" + minutes;
  };

  const options = {
    interaction: {
      mode: "index",
      intersect: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
        },
      },
      x: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val) {
            return formatTime(this.getLabelForValue(val));
          },
        },
      },
    },
  };

  const options2 = {...options,
    scales : {
      y: {
        ticks: {
          callback: function (val) {
            return val + '%';
          }
        }
      },
      x: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (val) {
            return formatTime(this.getLabelForValue(val));
          },
        },
      },
    }
  }

  const setChartData = (chartData, percentage, labels) => {
    return {
      labels,
      datasets: [
        {
          data: chartData,
          backgroundColor: ["#0000001a", "#0d6efd"],
          text: percentage + "%",
        },
      ],
    };
  };

  const setLineChartData = (
    chartData,
    label,
    color,
    borderWidth = 2,
    pointRadius = 1,
    borderDash = [],
    hoverPointRadius = 3
  ) => {
    return {
      label: label,
      borderColor: color,
      borderWidth: borderWidth,
      pointRadius: pointRadius,
      hoverPointRadius: hoverPointRadius,
      borderDash: borderDash,
      data: chartData?.map((s) => ({ x: formatDate(s.x), y: s.y })),
    };
  };

  useEffect(() => {
    getStatistics();
    return () => {
      source.cancel("axios request canceled");
    };
  }, [resolution]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="wrapper-statistics">
         <div className="ms-2 mt-2 d-flex align-items-center">
              <h5>Statistics</h5>
              <button className={`btn btn-primary mb-1 ms-2 ${resolution === 'hours' ? 'active' : ''}`} onClick={() => setResolution('hours')}>Hour</button>
              <button className={`btn btn-primary mb-1 ms-2 ${resolution === 'days' ? 'active' : ''}`} onClick={() => setResolution('days')}>Day</button>
          </div>
          

          {isLoading ? (
            <Loading fullScreen="true" default="true" />
          ) : (
            <>
              <div className="wrapper-charts" style={{ width: "100%", gap:"2rem" }}>
                <div className="chart" style={{padding:'0'}}>
                  <p>Daily email usage</p>
                  <DoughnutChart
                    data={setChartData(
                      [statistics.quota.sent, statistics.quota.available],
                      statistics.quota.available_percentage,
                      ["Sent", "Available"]
                    )}
                  />
                </div>
                <div
                  className="wrapper-sends-stats"
                  style={{
                    width: "1000px",
                    display: "flex",
                    justifyContent: "center",
                    flex: "1 1",
                  }}
                >
                  <Line
                    type="line"
                    data={{
                      datasets: [
                        setLineChartData(
                          statistics.charts.sends,
                          "Sends",
                          "blue"
                        ),
                      ],
                    }}
                    options={options}
                    style={{ width: "100%", height: "320px" }}
                    height={320}
                  />
                </div>
              </div>
              <div className="wrapper-charts" style={{ width: "100%" }}>
                <div
                  className="wrapper-complains-stats"
                  style={{
                    width: "500px",
                    display: "flex",
                    justifyContent: "center",
                    flex: "1 1",
                  }}
                >
                  <Line
                    type="line"
                    data={{
                      datasets: [
                        setLineChartData(
                          statistics.charts.complains.data,
                          "Complains",
                          "blue"
                        ),
                        setLineChartData(
                          statistics.charts.complains.warning,
                          "Warning",
                          "orange",
                          2,
                          0,
                          [5, 5],
                          0
                        ),
                        setLineChartData(
                          statistics.charts.complains.account_at_risk,
                          "Account at risk",
                          "red",
                          2,
                          0,
                          [5, 5],
                          0
                        ),
                      ],
                    }}
                    options={options2}
                    style={{ width: "100%", height: "320px" }}
                    height={320}
                  />
                </div>
                <div
                  className="wrapper-bounce-stats"
                  style={{
                    width: "500px",
                    display: "flex",
                    justifyContent: "center",
                    flex: "1 1",
                  }}
                >
                  <Line
                    type="line"
                    data={{
                      datasets: [
                        setLineChartData(
                          statistics.charts.bounce.data,
                          "Bounces",
                          "blue"
                        ),
                        setLineChartData(
                          statistics.charts.bounce.warning,
                          "Warning",
                          "orange",
                          2,
                          0,
                          [5, 5],
                          0
                        ),
                        setLineChartData(
                          statistics.charts.bounce.account_at_risk,
                          "Account at risk",
                          "red",
                          2,
                          0,
                          [5, 5],
                          0
                        ),
                      ],
                    }}
                    options={options2}
                    style={{ width: "100%", height: "320px" }}
                    height={320}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

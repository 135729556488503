import axios from "axios";
import { store } from '../app/store';
import { setLoggedOut } from '../features/auth/loginSlice';

const UNAUTHORIZED = 401;
const UNKNOWN = 419;
const NOT_FOUND = 404;
const {dispatch} = store;

let endpoint = process.env.REACT_APP_API_ENDPOINT;
const apiClient = axios.create({
  baseURL: endpoint,
  withCredentials: true,
});

apiClient.interceptors.response.use(async function (response) {
  return response;
}, async function (error) {
  const status = error.status || error.response?.status;
  if (status && (status === UNAUTHORIZED || status === UNKNOWN)) {
    window.location.href = '/';
    dispatch(setLoggedOut());
  }
  if (status && status === NOT_FOUND) {
    window.location.href = '/not-found';
  }
  return Promise.reject(error);
});


export default apiClient;

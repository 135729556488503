import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { fetchMailsInQueue } from '../features/home/eventsAPI';
import { Loading } from '../features/loader/Loading';
import {formatDateMedium} from '../help/help';
import Alert from './Alert';
export function MailsInQueue() {
    const [mailsInQueue, setMailsQueue] = useState(0);
    const [lastRun, setLastRun] = useState('');
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [error, setError] = useState(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        getMailsInQueue();
        return (() => {
            source.cancel('axios request canceled');
            setMailsQueue(0);
        });
      }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getMailsInQueue = async () => {
        try {
        setLoading(true);
        const obj = await fetchMailsInQueue(source);
        setMailsQueue(obj.mails_in_queue);
        setLastRun(formatDateMedium(obj.last_processed));
        setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
                setLoading(false);
            }
        }
    }

    if (isLoading) {
        return <Loading fullScreen="true" />;
    }

    return (
        <div>
            {error ? <Alert error={error} ></Alert> : ''}
            {`${mailsInQueue} mails in queue`} - Campaigns processed at { lastRun }
        </div>
    );
}

import React from 'react';

export function Loading(props) {
  if (props.default) {
    return (
      <div className="spinner-border text-dark ms-4" role="status">
          <span className="visually-hidden">Loading...</span>
      </div>
    )
  }
  return (
    <div className={`spinner-loading d-flex justify-content-center align-items-center ${ props.fullScreen === 'true' ? 'h-100 fullscreen' : 'pt-5' }`}>
        <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
  );
}

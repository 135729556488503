
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApprovalItem, getApprovalItems, getLoading, addStatus, removeStatus, getLoadingRemoveStatus, getManagers, getLoadingManagers, updateApprovalItem, fetchManagers, fetchCategories, removeApprovalItem } from './approvalItemsSlice';
import './approvalItems.scss';
import { Loading } from '../loader/Loading';
import Modal from '../../components/Modal';
import { checkPermission, formatDateMedium } from '../../help/help';
import { useNavigate, useLocation } from 'react-router';
import Select from 'react-select';
import { Tooltip } from '../../components/Tooltip';


export default function TableApprovalItems({type}) {

    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const dataTable = useSelector(getApprovalItems);
    const loading = useSelector(getLoading);
    const loadingRemoveStatus = useSelector(getLoadingRemoveStatus);
    const STATUSES = {1: 'Pending review', 20: 'Need Updating', 25: 'With the client for approval', 30: 'Preapproved', 40: 'Approved', 50: 'Rejected'};
    const [selectedItem, setSelectedItem] = useState(null);
    const [showAll, setShowAll] = useState(0);
    const comment = useRef(null);
    let navigate = useNavigate();
    let location = useLocation();
    const urlSearchParams = new URLSearchParams(location.search); 
    const managers = useSelector(getManagers);
    const loadingManagers = useSelector(getLoadingManagers);
    const selectedManager = useRef(null);
    const [selectedApprovalItem, setSelectedApprovalItem] = useState(null);
    const btnOpenModalRef = useRef(null);
    const [activeTr, setActiveTr] = useState(0);

    const dispatch = useDispatch();

    useEffect(() => {
        const myModal = document.getElementById('statuses');
        if (myModal) {
            myModal.addEventListener('hidden.bs.modal', onCloseModal);
        }
        return (() => {
            source.cancel('axios request canceled');
            if (myModal) {
                myModal.removeEventListener('hidden.bs.modal', onCloseModal);
            }
        });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(fetchApprovalItem({source, itemType: type, showAll: urlSearchParams.get('showAll')}));
        dispatch(fetchCategories({source, itemType: type}));
        if (urlSearchParams.get('showAll')) {
            setShowAll(true);
        }
    }, [urlSearchParams.get('showAll'), type]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let id =  urlSearchParams.get('id');
        if (urlSearchParams.get('id') && dataTable) {
            let data = dataTable.find(d => d.id?.toString() === id?.toString());
            setSelectedItem(data);
            if (data && btnOpenModalRef) {
                btnOpenModalRef.current.click();
                setActiveTr(data.id);
            }
            if (!data && dataTable.length > 0 && id > 0) {
                urlSearchParams.delete('id');
                navigate(`./?${urlSearchParams}`);
            } 
        }
    }, [urlSearchParams.get('id'), dataTable]); // eslint-disable-line react-hooks/exhaustive-deps

    const modalProps = {
        title: 'Statuses',
        target: 'statuses',
        disableFooter: true,
        size: 'xl'
    }

    const modalChangeManagerProps = {
        title: 'Change Manager',
        target: 'change_manager',
        add: () => {
            if (checkPermission('role_pr', 'fe-preapprove-item') || checkPermission('role_pr', 'fe-approve-item')) {
            let manager = selectedManager.current?.getValue();
            let data = {
                'assigned_user': manager.length > 0 ? manager[0]?.value : '',
            }
            dispatch(updateApprovalItem({source, data, id: selectedApprovalItem}));
            }
        }
    }

    const createStatus = (status) => {
        let data = {
            comment: comment.current?.value,
            status: status
        }
        let id = selectedItem.id;
        dispatch(addStatus({source, data, id}));
    }

    const classBtnByStatus = (type, status) => {
        switch (status) {
            case 20:
                return `${type}-warning`;
            case 50:
                return `${type}-danger`;
            case 40:
                return `${type}-success`;
            case 30:
                return `${type}-success`;
            default:
                return `${type}-primary`;
        }
    }

    const onClickStatus = (data) => {
        setSelectedItem(data);
        comment.current.value = '';
        urlSearchParams.delete('id');
        if (data.id) {
        urlSearchParams.append('id', data.id);
        }
        navigate(`./?${urlSearchParams}`);
    }

    const onDeleting = (id) => {
        if (window.confirm('Are you sure you want to delete status?')) {
            dispatch(removeStatus({source, id}));
            let _statuses = selectedItem?.statuses.filter(s => s.id !== id);
            setSelectedItem({...selectedItem, 'statuses': _statuses});
        }
    }

    const onChangeShowAll = (state) => {
        setShowAll(state);
        urlSearchParams.delete('showAll');
        if (state) {
        urlSearchParams.append('showAll', state);
        }
        navigate(`./?${urlSearchParams}`);
    }

    const formatOptionLabelManagers = ({ value, label, count }) => (
        <div>
            <div style={{ display: "flex" }}>
                <div style={{whiteSpace: "nowrap"}}>{label}</div>
                <div className="wrapper-text-company">
                    <span style={{color: 'silver'}}>{'(' + count + ')'}</span>
                </div>
            </div>
        </div>
    );

    const openModalChangeManager = (id) => {
        _getManagers();
        setSelectedApprovalItem(id);
        selectedManager.current?.setValue('');
    }

    const _getManagers = () => {
        dispatch(fetchManagers({source, itemType: type}))
    }

    const deleteApprovalItem = (id) => {
        if (window.confirm('Are you sure you want to delete the item?')) {
            dispatch(removeApprovalItem({source, id}));
        }
    }
    
    const onCloseModal = () => {
        let searchParams = new URLSearchParams(document.location.search)
        searchParams.delete('id')
        navigate(`./?${searchParams}`)
    }

    const removeActiveTr = () => {
        setActiveTr(0);
    }

    return (<>
        <button type="button" style={{display: 'none'}} data-bs-toggle="modal" data-bs-target="#statuses" ref={btnOpenModalRef}></button>
        <Modal props={modalProps}>
                <div className='mb-4'>
                    <a href={selectedItem?.url} target="_blank" rel="noreferrer">{selectedItem?.url}</a>
                </div>
                <div className="table-responsive">
                    {loadingRemoveStatus ? <Loading fullScreen="true" /> :
                        <table className="table table-striped table-approval-item">
                            <thead>
                                <tr>
                                    <th scope="col" className='row-7'>Name</th>
                                    <th scope="col" className='row-7'>Created at</th>
                                    <th scope="col" className='row-35'>Comment</th>
                                    <th scope="col">Status history</th>
                                    {checkPermission('role_pr', 'fe-approve-item') || checkPermission('role_pr', 'fe-preapprove-item') ? <>
                                    <th scope="col">Act.</th>
                                    </> : <></>}
                                </tr>
                            </thead>
                            <tbody>
                                {selectedItem?.statuses?.map((data, index) =>
                                    <tr key={index}>
                                        <td className='row-7'><div>{data.name}</div></td>
                                        <td className='row-7'><div>{formatDateMedium(data.created_at)}</div></td>
                                        <td className='row-35'>
                                            <Tooltip text={data.comment ? data.comment : ''}>
                                                <div>{data.comment}</div>
                                            </Tooltip>
                                        </td>
                                        <td className='row-7'><div><span className={classBtnByStatus('text',data.status)}>{STATUSES[data.status]}</span></div></td>
                                        {checkPermission('role_pr', 'fe-approve-item') || checkPermission('role_pr', 'fe-preapprove-item') ? <>
                                        <td >{index > 0 ? <i className="bi bi-x text-danger" onClick={() => onDeleting(data.id)}></i> : ''}</td>
                                        </> : <></>}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    }
                </div>
                <div className='mb-3'>
                    <label>Comment:</label>
                    <textarea ref={comment} className="form-control"></textarea>
                </div>                
                <div>
                    { selectedItem?.own_item? <button className='btn btn-primary me-1' data-bs-dismiss="modal" onClick={() => createStatus(1)}>Resend to Approval</button>:''}
                    { checkPermission('role_pr', 'fe-preapprove-item') || checkPermission('role_pr', 'fe-approve-item') ? <button className='btn btn-warning me-1' data-bs-dismiss="modal" onClick={() => createStatus(20)}>Need Updating</button>:''}                    
                    { checkPermission('role_pr', 'fe-preapprove-item') || checkPermission('role_pr', 'fe-approve-item') ? <button className='btn btn-primary me-1' data-bs-dismiss="modal" onClick={() => createStatus(25)}>With the client for approval</button>:''}
                    { checkPermission('role_pr', 'fe-preapprove-item') ? <button className='btn btn-success me-1' data-bs-dismiss="modal" onClick={() => createStatus(30)}>Preapproved</button>:''}
                    { checkPermission('role_pr', 'fe-approve-item') ? <button className='btn btn-success me-1' data-bs-dismiss="modal" onClick={() => createStatus(40)}>Approved</button>:''}
                    { checkPermission('role_pr', 'fe-preapprove-item') || checkPermission('role_pr', 'fe-approve-item') ? <button className='btn btn-danger me-1' data-bs-dismiss="modal" onClick={() => createStatus(50)}>Rejected</button>:''}
                </div>
                
        </Modal>
        <Modal props={modalChangeManagerProps}>
        <Select className="select me-2" options={managers ? managers.map((c) => ({value: c.id, label: c.name, count: c.count})): ''} ref={selectedManager} placeholder="Managers" isLoading={loadingManagers} formatOptionLabel={formatOptionLabelManagers} />
        </Modal>
        {loading ? <Loading fullScreen="true"/> : 
            <div className="row mt-3" onClick={(e) => removeActiveTr(e)}>
                {checkPermission('role_pr', 'fe-approve-item') || checkPermission('role_pr', 'fe-preapprove-item') ? 
                <div className="form-check form-switch me-auto">
                    <input className="form-check-input" type="checkbox" role="switch" id="show_hidden_card" checked={showAll ? true : false} onChange={() => onChangeShowAll(!showAll)}/>
                    <label className="form-check-label" htmlFor="show_hidden_card">Show All</label>
                </div> : ''}
                <div className="table-responsive">
                    <table className="table table-striped table-approval-item">
                        <thead>
                            <tr>
                                {checkPermission('role_pr', 'fe-approve-item')?<><th scope="col" className='row-7'>User</th></>:<></>}
                                <th scope="col">Site</th>
                                <th scope="col" className='row-7'>Assigned to</th>
                                <th scope="col" className="row-13">Url</th>
                                <th scope="col" className='row-13'>Comment</th>
                                <th scope="col" className='row-7'>Category</th>
                                <th scope="col">Status history</th>
                                {checkPermission('role_pr', 'fe-approve-item') || checkPermission('role_pr', 'fe-preapprove-item')?<th scope="col"></th>:<></>}
                                
                            </tr>
                        </thead>
                        <tbody>
                            {dataTable.map((data, index) =>
                                <tr key={index} className={activeTr === data.id ? 'outline-active-tr' : ''}>
                                    {data.id?<>
                                    {checkPermission('role_pr', 'fe-approve-item') ?<><td className='row-7'><div>{data.user_name}</div></td></>:<></>}
                                    <td><div>{data.site}</div></td>
                                    <td className='row-7'><div>{data.assigned_name}</div></td>
                                    <td className="row-13"><div><a href={data.url} target="_blank" rel="noreferrer">{data.url}</a></div></td>
                                    <td className="row-13">
                                        <Tooltip text={data.statuses.length > 0 ? data.statuses[data.statuses.length - 1].comment ? data.statuses[data.statuses.length - 1].comment : '' : data.description ? data.description : ''}>
                                            <div>{data.statuses.length > 0 ? data.statuses[data.statuses.length - 1].comment : data.description}</div>
                                        </Tooltip>
                                    </td>
                                    <td className='row-7'><div>{data.categories.map(c => c.name).join(', ')}</div></td>
                                    <td><button className={`btn status
                                        ${classBtnByStatus('btn',data.statuses.length > 0 ? data.statuses[data.statuses.length -1].status : 1)}`}
                                        onClick={() => onClickStatus(data)}>{data.statuses.length > 0 ? STATUSES[data.statuses[data.statuses.length - 1].status] : STATUSES[1]}</button></td>
                                    {checkPermission('role_pr', 'fe-approve-item') || checkPermission('role_pr', 'fe-preapprove-item') ?
                                    <td>
                                        <i onClick={() => openModalChangeManager(data.id)}className="ms-1 bi bi-person-fill cursor-pointer" data-bs-toggle="modal" data-bs-target="#change_manager"></i>
                                        <i onClick={() => deleteApprovalItem(data.id)}className="ms-1 bi bi-x cursor-pointer text-danger"></i>
                                    </td> : <></>}
                                    </>:<td colSpan={6} className='row-blank'>Blank</td>}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        }
    </>)
}
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiClient from '../../services/api';


export const updateJournalist = createAsyncThunk(
  'api/journalist',
  async ({id, body}) => {
    try {
      const response = await apiClient.put(`/api/journalist/${id}`, body);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const getUnsetJournalist = createAsyncThunk(
  'api/getUnsetJournalist',
  async ({id, inputValue, source}) => {
    try {
      const response = await apiClient.get(`/api/list-items/getUnsetJournalist/${id}/${inputValue}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);


export const createJournalist = createAsyncThunk(
  'api/createJournalist',
  async ({ id, values }) => {
    try {
      const response = await apiClient.post(`/api/list-items/createJournalist/${id}`, values);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const deleteJournalist = createAsyncThunk(
  'api/deleteJournalist',
  async ({ list_id, journalist_id }) => {
    try {
      await apiClient.post(`/api/list-items/deleteJournalist/${list_id}/${journalist_id}`);
      return journalist_id;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const fetchListItems = createAsyncThunk(
  'api/fetchListItems',
  async ({ id, source, per_page, page }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/api/list-items/list/${id}?${new URLSearchParams({
        page,
        per_page
      })}`, { cancelToken: source.token });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }
  }
);

export const importListItems = createAsyncThunk(
  'api/importListItems',
  async ({ id, formData, removeDuplicate }) => {
    try {
    const response = await apiClient.post(`/api/list-items/import/${id}/${removeDuplicate}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
    }    
  }
);

const initialState = {
  listItems: [],
  status: "idle",
  error: '',
  imported: 0
};

const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {
    resetStatusImported(state) {
      state.imported = 0;
    },
    resetListItems(state) {
      state.listItems = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchListItems.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchListItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.listItems = action.payload?.data;
      })
      .addCase(fetchListItems.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(importListItems.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.imported = 1;
      })
      .addCase(updateJournalist.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.listItems.findIndex(l => l.journalist_id === action.payload.id)
        state.listItems[index] = {...state.listItems[index], ...action.payload};
      })
      .addCase(deleteJournalist.fulfilled, (state, action) => {
        state.status = "succeeded";
        const index = state.listItems.findIndex(l => l.journalist_id === action.payload)
        const listItems = [...state.listItems];
        listItems.splice(index, 1);
        state.listItems = listItems;
      });
  },
});
export const { resetStatusImported, resetListItems } = listsSlice.actions;
export const listItems = (state) => state.lists.listItems;
export const isLoading = (state) => state.lists.status;
export const isImported = (state) => state.lists.imported;

export default listsSlice.reducer;
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Loading } from '../loader/Loading';
import { fetchLatestInvoices } from './clientInformationAPI';

export function LatestInvoicesTab({selectedTab, data, setError, tab}) {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    const [latestInvoices, setInvoices] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        return (() => {
            source.cancel('axios request canceled');
        });
    }, [selectedTab]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedTab === tab && data) {
            getLatestInvoices(data);
        }
    }, [selectedTab, data]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const getLatestInvoices = async (client) => {
        try {
            setLoading(true);
            const res = await fetchLatestInvoices(client, source);
            setInvoices(res);
            setLoading(false);
        } catch (error) {
            if (error?.message !== 'axios request canceled') {
                setError(error?.message);
                setLoading(false);
            }
        }
    }

    return (<>
        {isLoading ? <Loading fullScreen="true" /> : <>
            {selectedTab === tab ? <>
                <div className="row mt-4 ms-3 me-3">
                    {latestInvoices?.length > 0 ? <div className="table-responsive">
                        <table className="table table-striped table-clients-details">
                            <thead>
                                <tr>
                                    <th scope="col" className='col-campaign-date'>Company</th>
                                    <th scope="col" className="col-8-width">Invoice</th>
                                    <th scope="col" className="col-campaign-date text-center">Start Date</th>
                                    <th scope="col" className="col-campaign-date text-center">End Date</th>
                                    <th scope="col" className="col-campaign-date text-center">Fulfilled date</th>
                                    {/* <th scope="col" className="col-campaign-link text-center">Xero Link</th> */}
                                    <th scope="col" className="col-campaign-date text-center">Status</th>
                                    <th scope="col" className="col-8-width text-center">No. of campaigns</th>
                                    <th scope="col" className="col-no-links text-center">Campaign days</th>
                                    <th scope="col" className="col-8-width text-center">Min links</th>
                                    <th scope="col" className="col-no-links text-center">Links</th>
                                    <th scope="col" className="col-8-width text-center">Min HT Links</th>
                                    <th scope="col" className="col-no-links text-center">HT Links</th>
                                </tr>
                            </thead>
                            <tbody>
                                {latestInvoices.map((c, index) => {
                                return (<tr key={index}>
                                        <td className="col-campaign-date"><div>{c.company}</div></td>
                                        <td className="col-8-width"><div>{c.invoice}</div></td>
                                        <td className="col-campaign-date text-center">{c.start_date}</td>
                                        <td className="col-campaign-date text-center">{c.end_date}</td>
                                        <td className="col-campaign-date text-center">{c.fulfilled_date}</td>
                                        {/* <td className="col-campaign-link"><div><a href={c.xero_link} target="_blank" rel="noreferrer">{c.xero_link}</a></div></td> */}
                                        <td className="col-campaign-date text-center"><div>{c.status}</div></td>
                                        <td className="col-8-width text-center"><div>{c.no_of_campaigns}</div></td>
                                        <td className="col-8-width text-center"><div>{c.campaign_days}</div></td>
                                        <td className="col-8-width text-center"><div>{c.min_links}</div></td>
                                        <td className="col-no-links text-center"><div>{c.links}</div></td>
                                        <td className="mcol-8-width text-center"><div>{c.min_ht_links}</div></td>
                                        <td className="col-no-links text-center"><div>{c.ht_links}</div></td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div> : 'Not found'}
                   
                </div>
            </> : ''}
        </>}
    </>
    );
}
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/api";

export const fetchNotifications = createAsyncThunk(
  'api/fetchNotifications',
  async ({ source }) => {
    try {
      const response = await apiClient.get(`/api/notifications`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
      throw new Error();
    }
  }
);

export const fetchSilentNotification = createAsyncThunk(
  'api/fetchSilentNotification',
  async ({ source }) => {
    try {
      const response = await apiClient.get(`/api/notifications`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
      throw new Error();
    }
  }
);

export const markAllNotificationsAsRead = createAsyncThunk(
  'api/markAllNotificationsAsRead',
  async ({ source }) => {
    try {
      const response = await apiClient.post(`/api/notifications/mark-all-as-read`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
      throw new Error();
    }
  }
);

export const markNotificationAsRead = createAsyncThunk(
  'api/markNotificationAsRead',
  async ({id, source }) => {
    try {
      const response = await apiClient.post(`/api/notifications/${id}/mark-as-read`, {
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      if (error?.response?.data?.message) {
        throw new Error(error?.response?.data?.message);
      }
      throw new Error();
    }
  }
);

const initialState = {
  status: 'idle',
  error: '',
  notifications: [],
  notificationModalOpened: false,
  selectedNotificationId: null,
  unreadCount: null,
  selectedNotification: null,
  timeSleepModal: null
};

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setSelectedNotificationId: (state, action) => {
      state.selectedNotificationId = action.payload;
    },
    setNotificationModalOpened: (state, action) => {
      state.notificationModalOpened = action.payload;
    },
    setSelectedNotification: (state, action) => {
      state.selectedNotification = action.payload;
    },
    setTimeSleepModal: (state, action) => {
      state.timeSleepModal = action.payload;
    }

  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchNotifications.pending, (state, action) => {
        state.status = "loading";
        state.notifications = [];
      })
      .addCase(fetchNotifications.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.notifications = action.payload.data;
        state.unreadCount = action.payload.unread_count;
      })
      .addCase(fetchNotifications.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSilentNotification.fulfilled, (state, action) => {
        state.notifications = action.payload.data;
        state.unreadCount = action.payload.unread_count;
      })
      .addCase(fetchSilentNotification.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(markAllNotificationsAsRead.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(markAllNotificationsAsRead.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.length) {
          action.payload.forEach(n => {
              let indexOf = state.notifications.findIndex(notification => notification.id === n.id);
              state.notifications[indexOf].read_at = n.read_at;
              if (state.unreadCount > 0) {
                state.unreadCount = state.unreadCount - 1;
              }
          });
        }
      })
      .addCase(markAllNotificationsAsRead.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(markNotificationAsRead.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(markNotificationAsRead.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload.length) {
          action.payload.forEach(n => {
              let indexOf = state.notifications.findIndex(notification => notification.id === n.id);
              state.notifications[indexOf].read_at = n.read_at;
              if (state.unreadCount > 0) {
                state.unreadCount = state.unreadCount - 1;
              }
          });
        }
      })
      .addCase(markNotificationAsRead.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default notificationsSlice.reducer;

export const getNotifications = (state) => state.notifications.notifications;
export const getStatus = (state) => state.notifications.status;
export const getError = (state) => state.notifications.error;
export const getSelectedNotificationId = (state) => state.notifications.selectedNotificationId;
export const getNotificationModalOpened = (state) => state.notifications.notificationModalOpened;
export const getUnreadCount = (state) => state.notifications.unreadCount;
export const getSelectedNotification = (state) => state.notifications.selectedNotification;
export const getTimeSleepModal = (state) => state.notifications.timeSleepModal;

export const { setSelectedNotificationId, setNotificationModalOpened, setSelectedNotification, setTimeSleepModal } = notificationsSlice.actions;
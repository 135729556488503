import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Login.scss';
import { setLoggedOut } from './loginSlice';
import { toggleLoading, isLoading } from '../loader/loadingSlice';
import { Loading } from '../loader/Loading';
export default function Logout() {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const logout = async () => {
    dispatch(toggleLoading());
    dispatch(setLoggedOut());
    dispatch(toggleLoading());
  };
  if (loading) {
    return <Loading fullScreen="true" />
  }
  return (
    <button
      className="btn btn-light btn-logout"
      onClick={() => logout()}
    >
      LogOut
    </button>
  );
}